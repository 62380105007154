export const CLOSE_TOAST = "CLOSE_TOAST";
export const OPEN_AND_SET_TOAST_CONTENT = "OPEN_AND_SET_TOAST_CONTENT";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_AND_SET_MODAL_CONTENT = "OPEN_AND_SET_MODAL_CONTENT";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const OPEN_LOADER = "OPEN_LOADER";
export const SAVE_PLAN = "SAVE_PLAN";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
export const SAVE_QUERY = "SAVE_QUERY";
export const SAVE_LOAN = "SAVE_LOAN";
export const SAVE_CARD = "SAVE_CARD";
export const CHANGE_NAVBAR = "CHANGE_NAVBAR";
export const OPEN_AND_SET_NEW_MODAL_CONTENT = "OPEN_AND_SET_NEW_MODAL_CONTENT";
export const CLOSE_NEW_MODAL = "CLOSE_NEW_MODAL";
export const SAVE_SUBSIDIARY_INFO = "SAVE_SUBSIDIARY_INFO";
export const GET_MCCS = "GET_MCCS";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_SUBSIDIARY_GROUPS = "GET_SUBSIDIARY_GROUPS";
