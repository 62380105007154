import React, { useState } from 'react';
import styles from './Signin.module.scss';
import Logo from '../../assets/images/Logos/Pay-Invert-Logo.svg';
import { ReactComponent as SignInIcon } from '../../assets/images/Icons/signin.svg';
import { ReactComponent as AccountIcon } from '../../assets/images/Icons/account.svg';
import OutlineTextInput from '../../components/input/OutlineTextInput';
import {
	openLoader,
	closeLoader,
} from '../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import client from '../../api/client';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import { saveSubsidiaryInfo } from '../../redux/actions/subsidiaryInfo/subsidiaryInfoActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';
import Modal from 'react-modal';
import ResetPasswordModal from './resetModal/ResetPasswordModal';
import { customStyles } from '../../constants/modalStyles';
import Img from '../../assets/images/Logos/landing.png';
import logo from '../../assets/images/Logos/paymentgateway.svg';
import OtpCompleteModal from './otpCompleteModal/OtpCompleteModal';
import showPwdImg from '../../assets/images/Icons/Icons/show-password.svg';
import hidePwdImg from '../../assets/images/Icons/Icons/hide-password.svg';

const SignIn = () => {
	const todaysDate = new Date();
	const dispatch = useDispatch();
	const [isRevealPwd, setIsRevealPwd] = useState(false);
	const history = useHistory();
	interface initTypes {
		Email: string;
		Password?: string;
	}

	const initProps: initTypes = {
		Email: '',
		Password: '',
	};
	const [inputs, setInputs] = React.useState(initProps);
	const [otp, setOtp] = React.useState<number | null>(null);

	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [modalIsOtpOpen, setIsOtpOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	function closeOtpModal() {
		setIsOtpOpen(false);
	}

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		client
			.post('login', inputs)
			.then((data: any) => {
				dispatch(closeLoader());
				setIsOtpOpen(true);
				// dispatch(saveSubsidiaryInfo(data));
				// const { status, message, token } = data.data;
				// const { access_token } = token;
				// sessionStorage.setItem('access_token', access_token);

				// if (status === 'success') {
				// 	history.push('/dashboard');
				// }
			})
			.catch((error: any) => {
				// <ErrorToast error={error} />;
				if (error.response) {
					dispatch(closeLoader());
					const { message } = error.response.data;
					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastErrorStyles,
						})
					);
				} else if (error.request) {
					dispatch(closeLoader());
				} else {
					dispatch(closeLoader());
				}
			});
	};

	const handleCompleteSubmit = (e: React.SyntheticEvent) => {
		// e.preventDefault();
		dispatch(openLoader());
		client
			.post('login/complete', {
				email: inputs.Email,
				otp,
			})
			.then((data: any) => {
				dispatch(closeLoader());
				dispatch(saveSubsidiaryInfo(data.data.user));
				const { status, message, token } = data.data;
				const { accessToken } = token;
				sessionStorage.setItem('accessToken', accessToken);
				closeOtpModal();
				if (status === 'success') {
					console.log("login successful")
					history.push('/dashboard');
				}
			})
			.catch((error: any) => {
				// <ErrorToast error={error} />;
				if (error.response) {
					dispatch(closeLoader());
					const { message } = error.response.data;
					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastErrorStyles,
						})
					);
				} else if (error.request) {
					dispatch(closeLoader());
				} else {
					dispatch(closeLoader());
				}
			});
	};

	return (
		<div className={styles.signWrapper}>
			<div className={styles.rightwrapper}>
				<img className={styles.rightwrapper_img} src={Img} alt='' />
			</div>
			<div className={styles.leftwrapper}>
				{/* <div className={styles.image}>
					<img src={logo} alt='' />
				</div> */}
				<div className={styles.formwrapper}>
					<h1 className={styles.form_heading}>Sign in to your Account</h1>
					<p className={styles.form_p}>
						Enter your details to access your account
					</p>
					<div className={styles.businessForm}>
						<form onSubmit={handleSubmit} className={styles.form}>
							<OutlineTextInput
								style={{ marginTop: '10px' }}
								handleChange={updateProps}
								inputName='Email'
								inputLabel='Email Address'
								inputValue={inputs.Email}
							/>
							<div className={styles.passwordInp}>
								<OutlineTextInput
									style={{ marginTop: '20px' }}
									handleChange={updateProps}
									inputName='Password'
									inputLabel='Password'
									inputType={isRevealPwd ? 'text' : 'password'}
									inputValue={inputs.Password}
								/>
								<img
									alt='/'
									className={styles.inputImg}
									title={isRevealPwd ? 'Hide password' : 'Show password'}
									src={isRevealPwd ? hidePwdImg : showPwdImg}
									onClick={() => setIsRevealPwd((prevState) => !prevState)}
								/>
							</div>
							<p className={styles.forgetPassword}>
								Forgot Password?{' '}
								<span
									onClick={() => setIsOpen(true)}
									className={styles.forgetPasswordspan}>
									Reset here
								</span>
							</p>
							<button onClick={handleSubmit} className={styles.signInButton}>
								Sign in
							</button>
						</form>
					</div>
				</div>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<ResetPasswordModal closeModal={closeModal} />
			</Modal>
			<Modal
				isOpen={modalIsOtpOpen}
				onRequestClose={closeOtpModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<OtpCompleteModal
					trigger={handleCompleteSubmit}
					setOtp={setOtp}
					otp={otp}
					closeModal={closeOtpModal}
				/>
			</Modal>
		</div>
	);
};

export default SignIn;
