import { CHANGE_CURRENCY, GET_CURRENCIES, GET_MCCS, GET_SUBSIDIARY_GROUPS } from "../constants";
import { ReactNode } from "react";
import currencyApi from '../../../api/currencies';

export const changeCurrency = (currency: any) => {
  return {
    type: CHANGE_CURRENCY,
    currency,
  };
};

export const getMccsAction = (mccs: any[]) => {
  return {
    type: GET_MCCS,
    mccs,
  };
};

export const getSubsidiaryGroupsAction = (subsidiaryGroups: any[]) => {
  return {
    type: GET_SUBSIDIARY_GROUPS,
    subsidiaryGroups,
  };
};

export const getCurrenciesAction = async () => {
  const res: any = await currencyApi.getCurrencies();
  const currencies = res?.data?.data ?? []
  return {
    type: GET_CURRENCIES,
    currencies,
  };
};
