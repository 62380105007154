import React from "react";
import styles from "./Statusview.module.scss";

type StatusProps = {
  status: string;
  green?: string;
  red?: string;
  orange?: string;
  purple?: string;
  blue?: string;
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
};
const StatusView = ({
  status,
  green,
  orange,
  red,
  blue,
  purple,
  value,
  setValue,
}: StatusProps) => {

  return (
    <div className={styles.wrapper}>
      {status === red ? (<div className={styles.declined}>{red}</div>)
        : status === orange ? (<div className={styles.processing}>{orange}</div>)
          : status === green ? (<div className={styles.completed}>{green}</div>)
            : status === purple ? (<div className={styles.purple}>{purple}</div>)
              : status === blue ? (<div className={styles.review}>{blue}</div>)
                : (<div className={styles.processing}>{status}</div>)
      }
    </div>
  );
};

export default StatusView;
