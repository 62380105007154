/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react';
import styles from './Transactions.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import {
	TransactionsData,
	ColumnTransactions,
} from '../../types/TablesTypes/Transactions';
import MenuOption from '../../components/MenuOption/MenuOption';
import StatusView from '../../components/StatusView/StatusView';
import TableHeader from '../../components/TableHeader/TableHeader';
import ViewIcon from '../../assets/images/Icons/view-details.svg';
import transactionsApi from '../../api/transactions';
import useApi from '../../hooks/useApi';
import client from '../../api/client';
import { useDispatch, useSelector } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
} from '../../constants/toastStyles';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import CreateCustomer from '../../components/ModalContent/CreateCustomer/CreateCustomer';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import TransactionDetails from './TransactionDetails/TransactionDetails';
import { Dayjs } from 'dayjs';
import FilterModal from '../../components/filterConfig/FilterModal';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../utils/datefunction';
import FlagTransactionModal from '../../components/ModalContent/FlagTransactionModal/FlagTransactionModal';

const tableStatus = [
	{ id: 1, name: 'Initiated' },
	{ id: 2, name: 'Pending' },
	{ id: 3, name: 'Awaiting-Confirmation' },
	{ id: 4, name: 'Failed' },
	{ id: 5, name: 'Reversed' },
	{ id: 6, name: 'Processing' },
	{ id: 7, name: 'Successful' },
];

const AllTransactions = ({ etransactions, cb }: any) => {
	const [tableRow, setTableRow] = useState<any[]>();
	// const [transactionId, setTransactionId] = useState<number | null>(null);
	const [transaction, setTransaction] = useState({
		id: 0, orderPaymentId: 0
	});
	const [transactions, setTransactions] = useState<any>(etransactions);

	const [modalIsOpenSingle, setIsOpenSingle] = React.useState({
		isOpened: false,
		data: null
	});

	function closeModalSingle() {
		setIsOpenSingle({ isOpened: false, data: null });
		cb && cb();
	}

	const getTransactionsApi = useApi(transactionsApi.getTransactions);
	// const settlementApiHandler = useApi(settlementApi.toggleFlagSettlement);

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	const dispatch = useDispatch();
	const history = useHistory();


	const { currency } = useSelector((state) => state.appSettingsReducer);

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [paymentreference, setPaymentReference] = useState('');
	const [orderreference, setOrderReference] = useState('');
	const [subsidiaryId, setSubsidiaryId] = useState('');
	const [customerId, setcustomerId] = useState('');
	const [status, setStatus] = useState('');
	const [email, setEmail] = useState('');
	const [bearer, setBearer] = useState(false);

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentReference('');
		setOrderReference('');
		setStatus('');
		setSubsidiaryId('');
		setcustomerId('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray = [
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [
				{ name: 'Successful' },
				{ name: 'Initiated' },
				{ name: 'Failed' },
				{ name: 'Pending' },
				{ name: 'Processing' },
				{ name: 'Awaiting-Confirmation' },
				{ name: 'Reversed' },
			],
		},
		{
			name: 'Payment Reference',
			value: paymentreference,
			setValue: setPaymentReference,
		},
		{
			name: 'Order Reference',
			value: orderreference,
			setValue: setOrderReference,
		},
		{
			name: 'Merchant Id',
			value: subsidiaryId,
			setValue: setSubsidiaryId,
		},
		{
			name: 'Customer Id',
			value: customerId,
			setValue: setcustomerId,
		},
	];

	const fetchTransactions = async (isExport = false) => {
		status.replace(/-|\s/g, '');
		try {
			if (etransactions) return
			let page = pageNumber;
			let rpp = rowsPerPage
			if (isExport) {
				page = 1
				rpp = 10000
			}
			dispatch(openLoader());
			const { data }: any = await client.get(
				`order/all/paginated?currency=${currency}&subsidiaryId=${subsidiaryId}&customerId=${customerId}&email=${email}&status=${status}&paymentreference=${paymentreference}&orderreference=${orderreference}&fromdate=${fromDate}&todate=${toDate}&limit=${rpp}&page=${page}&IsDownload=${isExport}`
			);
			if (!isExport) {
				setTransactions(data.data);
				dispatch(closeLoader());
				setBearer(false);
			}
			return data.data.items
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	const fetchTransactionExports = async () => {
		return fetchTransactions(true);
	};

	useEffect(() => {
		fetchTransactions();
	}, [bearer, pageNumber, rowsPerPage, email, currency]);

	useEffect(() => {
		let payments = etransactions ?? transactions
		console.log({ payments })
		setPageNumber(payments?.page?.currentPage || 1);
		setPreviousPage(payments?.page?.currentPage - 1);
		setNextPage(payments?.page?.currentPage + 1);
		if (etransactions) setTransactions(payments)
	}, [transactions, etransactions]);

	const openTransactionDetails = async (t: any) => {
		// history.push(`/dashboard/transactions/${t?.id}?orderPaymentId=${t?.orderPaymentId}`)
		setTransaction(t);

	};
	const menuOptions = [
		{
			id: 1,
			name: (t: any) => {
				return 'View Transaction';
			},
			icon: ViewIcon,
			onclick: (t: any) => {
				// history.push(`/dashboard/transactions/${t?.id}?orderPaymentId=${t?.orderPaymentId}`)
				setTransaction(t?.order ?? t);
			},
		},
		{
			id: 2,
			name: (t: any) => {
				return (t.settlementStatusId == 5 || t.settlementStatusId == 4) ? "Settlement processed" : t.settlementStatusId == 2 ? 'Unflag Settlement' : 'Flag Settlement';
			},
			icon: ViewIcon,
			onclick: (t: any) => {
				if (t.settlementStatusId == 5 || t.settlementStatusId == 4) return;
				setIsOpenSingle({ isOpened: true, data: t })
			},
		},
	];

	const dataTransactions = () => {
		const tempArr: TransactionsData[] = [];
		transactions?.items
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				return tempArr.push({
					date: moment(transaction?.dateCreated).format('MMMM Do YYYY, h:mm a'),
					merchantName: transaction?.subsidiaryName ?? transaction?.order?.subsidiary?.name,
					merchantId: transaction?.subsidiaryId,
					id: transaction?.order?.id ?? transaction?.id,
					orderPaymentId: transaction?.order?.orderPaymentId ?? transaction?.orderPaymentId,
					customerId: transaction?.customerId ?? transaction?.order?.customer?.id,
					customerName: transaction?.customerName ?? `${transaction?.order?.customer?.firstName} ${transaction?.order?.customer?.lastName}`,
					email: transaction?.customerEmail ?? transaction?.order?.customer?.emailAddress,
					transactionAmount: `${transaction?.currency?.shortName ?? transaction?.currency} ${transaction?.amountFormatted ?? transaction?.transactionAmount}`,
					paymentChannel: transaction?.paymentTypeName ?? transaction?.order?.paymentTypeName ?? transaction?.order?.paymentType ?? 'NIL',
					status: (
						<StatusView
							status={transaction?.orderStatus ?? transaction?.status?.name}
							green='Successful'
							blue='Initiated'
							orange='Pending'
							red='Failed'
							purple='Reversed'
						/>
					),
					action: (!etransactions ? "" :
						<MenuOption
							menuContent={
								<div>
									{menuOptions.map((option: any) => (
										<div
											key={option?.id}
											onClick={(e) => {
												option?.onclick(transaction);
											}}
											className={styles.menuContent}>
											<img
												src={option?.icon}
												alt={option?.name(transaction)}
												width={24}
												height={24}
											/>
											<div className={styles.optionName}>
												{option?.name(transaction)}
											</div>
										</div>
									))}
								</div>
							}
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransactions());
	}, [transactions?.items, currency]);

	return (
		<div style={{ minHeight: '100vh' }}>
			{!transaction.id ? (
				<>
					<TableHeader
						pageName={etransactions ? 'Settlement Transaction' : 'All Transactions'}
						data={transactions?.items}
						dataLength={transactions?.page?.total}
						value={email}
						setValue={setEmail}
						dropdown={dropdown}
						setDropdown={setDropdown}
						searchfn={etransactions ? false : true}
						placeHolder="Search by customer email address"
						goBack={cb}
						exporting={true}
						fetchDataHandler={fetchTransactionExports}
						FilterComponent={
							<FilterModal
								eventDate={eventDate}
								setEventDate={setEventDate}
								dropdown={dropdown}
								setDropdown={setDropdown}
								setFromDate={setFromDate}
								setToDate={setToDate}
								fromDate={fromDate}
								toDate={toDate}
								setBearer={setBearer}
								clearHandler={clearHandler}
								filteredArray={filteredArray}
							/>
						}
					/>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel='Example Modal'
						style={customStyles}>
						<CreateCustomer
							fetchtransactions={fetchTransactions}
							closeModal={closeModal}
						/>
					</Modal>
					{getTransactionsApi.loading ? (
						<div className={styles.loading} />
					) : (
						<PaginationTable
							data={tableRow ? tableRow : []}
							columns={ColumnTransactions ? ColumnTransactions : []}
							value={value}
							emptyPlaceHolder={
								transactions?.page?.total == 0
									? 'You currently do not have any transactions'
									: 'Loading...'
							}
							total={transactions?.page?.total}
							totalPage={transactions?.page?.totalPage}
							pageNumber={pageNumber}
							setPageNumber={setPageNumber}
							nextPage={nextPage}
							clickAction={etransactions ? null : (obj) => openTransactionDetails(obj)}
							setNextPage={setNextPage}
							previousPage={previousPage}
							setPreviousPage={setPreviousPage}
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
						/>
					)}
				</>
			) : (
				<TransactionDetails id={transaction.id} orderPaymentId={transaction.orderPaymentId} setId={setTransaction} />
			)}

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle.isOpened}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<FlagTransactionModal closeModal={closeModalSingle} data={modalIsOpenSingle.data} />
			</Modal>
		</div>
	);
};

export default AllTransactions;
