export function randnumber(length: number) {
  return Math.random().toString(16).substring(2, 2 + length)
}

export function getQueryParams(queryString: string) {
  // Remove leading '?' if present
  if (queryString.startsWith('?')) {
    queryString = queryString.substring(1);
  }
  const params: any = {};
  const pairs = queryString.split('&');
  for (const pair of pairs) {
    if (!pair) continue; // Skip empty segments (in case of trailing '&')
    const [key, value] = pair.split('=');
    // Decode the value (and handle the case when there's no value)
    params[key] = value ? decodeURIComponent(value) : '';
  }
  return params;
}