import React, { useState, useEffect } from 'react';
import styles from './Invoicedetails.module.scss';
import client from '../../../api/client';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import ChargebackIcon from '../../../assets/images/Icons/chargeback-white.svg';
import StatusView from '../../../components/StatusView/StatusView';

interface IDtypes {
	id: number | null;
	setId: React.Dispatch<React.SetStateAction<number | null>>;
}

const InvoiceDetails = ({ id, setId }: IDtypes) => {
	const [customerDetails, setCustomerDetails] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (id) {
			dispatch(openLoader());
			setIsLoading(true);
			client
				.get(`/invoice/detail/${id}`)
				.then((res: any) => {
					setCustomerDetails(res?.data?.data);
					setIsLoading(false);
					dispatch(closeLoader());
				})
				.catch((error) => {
					dispatch(closeLoader());
					setIsLoading(false);
					if (error.response) {
						const message = error?.response?.data?.message;
						dispatch(
							openToastAndSetContent({
								toastStyles: ToastErrorStyles,
								toastContent: message,
							})
						);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log('Error', error.message);
					}
				});
		}
	}, [id]);

	const payload = {
		order_id: id,
		amount: customerDetails?.amount,
	};

	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<>
					<div className={styles.transactionInfo}>
						<div className={styles.transactionsHeader}>
							<div
								style={{
									color: '#616161',
									display: 'inline',
									cursor: 'pointer',
								}}
								onClick={() => setId(null)}>
								Invoice
							</div>
							{' >'} Invoice details
						</div>
					</div>
					<div className={styles.topTable}>
						<div className={styles.detailsHeader}>Invoice details</div>
						<div className={styles.tableContent}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Invoice number</div>
								<div className={styles.detailsKey}>
									{customerDetails?.reference}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Customer name</div>
								<div className={styles.detailsKey}>
									{customerDetails?.customerName}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Customer email</div>
								<div className={styles.detailsKey}>
									{customerDetails?.customerEmail}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Customer phone</div>
								<div className={styles.detailsKey}>
									{customerDetails?.customerPhone || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Merchant ID</div>
								<div className={styles.detailsKey}>
									{customerDetails?.subsidiaryId || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Account number</div>
								<div className={styles.detailsKey}>
									{customerDetails?.accountNumber || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Card Type</div>
								<div className={styles.detailsKey}>
									{customerDetails?.cardType || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Masked PAN</div>
								<div className={styles.detailsKey}>
									{customerDetails?.maskedPan || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>IP Addresss</div>
								<div className={styles.detailsKey}>
									{customerDetails?.ipAddress || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Transaction amount</div>
								<div className={styles.detailsKey}>
									{customerDetails?.amount || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Payment Reference</div>
								<div className={styles.detailsKey}>
									{customerDetails?.paymentReference || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Provider</div>
								<div className={styles.detailsKey}>
									{customerDetails?.orderStatus || 'Not provided'}
								</div>
							</div>
							{/* <div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Country</div>
								<div className={styles.detailsKey}>
									{' '}
									{customerDetails?.order_status}
								</div>
							</div> */}
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Payment channel</div>
								<div className={styles.detailsKey}>
									{customerDetails?.paymentType || 'Not provided'}
								</div>
							</div>
							{/* <div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Device fingerprint</div>
								<div className={styles.detailsKey}>XXXXXXXXXXXX</div>
							</div> */}
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Status</div>
								<div className={styles.detailsKey}>
									<StatusView
										status={customerDetails?.status}
										green='Successful'
										orange='Pending'
										red='Failed'
									/>
								</div>
							</div>{' '}
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Merchant name</div>
								<div className={styles.detailsKey}>
									{customerDetails?.subsidiaryName || 'Not provided'}
								</div>
							</div>{' '}
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Order Reference</div>
								<div className={styles.detailsKey}>
									{customerDetails?.orderReference || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Invoice notes</div>
								<div className={styles.detailsKey}>
									{customerDetails?.remarks || 'Not provided'}
								</div>
							</div>
							{/* <div className={styles.chargeBack}>
								<button
									className={styles.chargeBackbutton}
									onClick={handleChargebacks}>
									<div>
										<img src={ChargebackIcon} alt='chargeback' />
									</div>
									<div className={styles.ml}>Initiate Chargeback</div>
								</button>
							</div> */}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default InvoiceDetails;
