import React, { useState, useEffect } from 'react';
import styles from './PendingLimit.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import { ColumnGen, GenData } from '../../../types/TablesTypes/fees';
import TableHeader from '../../../components/TableHeader/TableHeader';
import client from '../../../api/client';
import moment from 'moment';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import ViewIcon from '../../../assets/images/Icons/view-details.svg';
import Times from '../../../assets/images/Icons/u_multiply (2).svg';
import { Row, Col } from 'antd';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import Mark from '../../../assets/images/Icons/u_save.svg';
import { Divider } from 'antd';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import { Dayjs } from 'dayjs';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../utils/datefunction';
import * as Yup from 'yup';
import FilterModal from '../../../components/filterConfig/FilterModal';
import MenuOption from '../../../components/MenuOption/MenuOption';
import Approved from '../Approved/Approved';
import Declined from '../Declined/Declined';

const PendingLimit = ({ title }: { title: string }) => {
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [reason, setReason] = useState('');
	const [details, setDetails] = useState<any>({});
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [modalIsOpenApproved, setIsOpenApproved] = React.useState(false);
	const [modalIsOpenDeclined, setIsOpenDeclined] = React.useState(false);
	const [fees, setFees] = useState<any>();
	interface initTypes {
		country: string;
		paymentType: string;
		percentageValue: string;
		flatValue: string;
		currency: string;
	}

	const initProps: initTypes = {
		country: '',
		paymentType: '',
		percentageValue: '',
		flatValue: '',
		currency: '',
	};

	const currencyOption = [{ name: 'USD' }, { name: 'NGN' }];
	const validate = Yup.object({
		country: Yup.string().required('Required'),
		payment_type: Yup.string().required('Required'),
		percentage_value: Yup.string().required('Required'),
		flat_value: Yup.string().required('Required'),
		currency: Yup.string().required('Required'),
	});
	// const [apiObject, setApiObject] = useState(null);

	// const { keyB: trxRef } = apiObject?.keyA || [{

	// }];

	function closeModalSingle() {
		setIsOpenSingle(false);
	}
	function closeModalApproved() {
		setIsOpenApproved(false);
		setIsOpenDeclined(false);
		closeModalSingle()
	}
	// function closeModalDeclined() {
	// 	setIsOpenDeclined(false);
	// 	closeModalSingle()
	// }

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [country, setCountry] = useState('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);
	const [businessname, setBusinessname] = useState('');
	const [businesstype, setBusinesstype] = useState('');

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setCountry('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
		setBusinesstype('');
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray = [
		{
			name: 'Business Type',
			value: businesstype,
			setValue: setBusinesstype,
			selective: [{ name: 'Individual' }, { name: 'Company' }],
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [
				{ name: 'Active' },
				{ name: 'Pending-Verification' },
				{ name: 'New' },
				{ name: 'Pending-Approval' },
			],
		},
	];
	const menuOptions = [
		{
			id: 1,
			name: () => {
				return 'View limit details';
			},
			icon: ViewIcon,
			onclick: (details: any, e: any) => {
				setDetails(details);
				setIsOpenSingle(true);
			},
		},
	];

	const fetchBusinesses = async () => {
		dispatch(openLoader());
		try {
			const res: any = await client.get(
				`/limits/pending?status=${status}&limit=${rowsPerPage}&page=${pageNumber}`
			);
			setFees(res.data.data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchBusinesses();
	}, [bearer, pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(fees?.page?.currentPage || 1);
		setPreviousPage(fees?.page?.previousPage);
		setNextPage(fees?.page?.nextPage);
	}, [fees]);

	const dataTransaction = () => {
		const tempArr: GenData[] = [];
		fees?.items
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					currency: transaction.currency,
					requester: transaction.requester,
					frequency: transaction.frequency,
					type: transaction.type,
					limitGroup: transaction.limitGroup,
					singleTransferLimit: transaction?.singleTransferLimit,
					cummulativeTransferLimit: transaction?.cummulativeTransferLimit,
					dateCreated: moment(transaction?.dateCreated).format('MMMM Do YYYY, h:mm a'),
					status: transaction?.status,
					action: (
						<MenuOption
							menuContent={
								<div>
									{menuOptions.map((option: any) => (
										<div
											key={option?.id}
											onClick={(e) => {
												option?.onclick(transaction, e);
											}}
											className={styles.menuContent}>
											<img
												src={option?.icon}
												alt={option?.name()}
												width={24}
												height={24}
											/>
											<div className={styles.optionName}>{option?.name()}</div>
										</div>
									))}
								</div>
							}
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [fees?.items]);

	return (
		<div style={{ marginTop: '38px' }}>
			<TableHeader
				pageName={title}
				dataLength={fees?.page?.total}
				data={fees?.items}
				value={value}
				setValue={setValue}
				exporting={false}
				filtering={false}
				searchfn={false}
				FilterComponent={
					<FilterModal
						eventDate={eventDate}
						setEventDate={setEventDate}
						dropdown={dropdown}
						setDropdown={setDropdown}
						setFromDate={setFromDate}
						setToDate={setToDate}
						fromDate={fromDate}
						toDate={toDate}
						setBearer={setBearer}
						clearHandler={clearHandler}
						filteredArray={filteredArray}
					/>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnGen ? ColumnGen : []}
				value={value}
				emptyPlaceHolder={
					fees?.page?.total == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={fees?.page?.total}
				totalPage={fees?.page?.totalPage}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<div className={styles.modalwrapper}>
					<div className={styles.modalhead}>
						<h3 className={styles.modalheadh3}>Pending approval</h3>
						<CloseIcon
							onClick={closeModalSingle}
							style={{ cursor: 'pointer' }}
						/>
					</div>
					<Divider style={{ margin: 0, padding: 0 }} />
					<div
						style={{
							padding: '32px 24px',
						}}>
						<Row gutter={[16, 24]}>
							<Col span={8}>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Pending ID</div>
									<div className={styles.detailsKey}>{details?.id}</div>
								</div>
							</Col>
							<Col span={8}>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Date created</div>
									<div className={styles.detailsKey}>
										{moment(details?.dateCreated).format(
											'MMMM Do YYYY, h:mm a'
										)}
									</div>
								</div>
							</Col>
							<Col span={8}>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>Type</div>
									<div className={styles.detailsKey}> {details.type}</div>
								</div>
							</Col>
							<Col span={24}>
								<textarea
									value={reason}
									onChange={(e) => setReason(e.target.value)}
									rows={4}
									cols={50}
									style={{ width: '100%', padding: '5px 10px' }}
									placeholder='Give your reason'
								/>
							</Col>
							<Col span={12}>
								<button
									onClick={() => setIsOpenDeclined(true)}
									className={styles.declinebutton}>
									<div style={{ marginRight: '8px' }}>
										<img src={Times} alt='chargeback' />
									</div>
									<div className={styles.ml}>Decline</div>
								</button>
							</Col>
							<Col span={12}>
								<button
									onClick={() => setIsOpenApproved(true)}
									className={styles.approvebutton}>
									<div style={{ marginRight: '8px' }}>
										<img src={Mark} alt='chargeback' />
									</div>
									<div className={styles.ml}>Approve</div>
								</button>
							</Col>
						</Row>
					</div>
				</div>
			</Modal>

			{/* //MODAL FOR APPROVED */}
			<Modal
				isOpen={modalIsOpenApproved}
				onRequestClose={closeModalApproved}
				contentLabel='Example Modal'
				style={customStyles}>
				<Approved
					closeModal={closeModalApproved}
					reason={reason}
					details={details}
					identifier='limit'
					fn={fetchBusinesses}
				/>
			</Modal>

			{/* //MODAL FOR DECLINED */}
			<Modal
				isOpen={modalIsOpenDeclined}
				onRequestClose={closeModalApproved}
				contentLabel='Example Modal'
				style={customStyles}>
				<Approved
					closeModal={closeModalApproved}
					reason={reason}
					isdecline={true}
					details={details}
					identifier='limit'
					fn={fetchBusinesses}
				/>
			</Modal>
		</div>
	);
};

export default PendingLimit;
