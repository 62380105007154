import React, { useState, Fragment, useEffect } from 'react';
import styles from './Settlements.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import {
	ColumnSettlementsFull,
	SettlementsFullData,
} from '../../../types/TablesTypes/Settlementsful';
import StatusView from '../../../components/StatusView/StatusView';
import TableHeader from '../../../components/TableHeader/TableHeader';
import TransactionsMenu from '../../../components/MenuOption/TransactionsMenu/TransactionsMenu';
import transactionsApi from '../../../api/transactions';
import useApi from '../../../hooks/useApi';
import client from '../../../api/client';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch, useSelector } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	AppstoreOutlined,
	MailOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import Filtered from '../../../components/filterComponent/Filtered';
import { menuContent } from '../../../utils/menuContent';
import RefundsMenu from '../../../components/MenuOption/RefundsMenu/RefundsMenu';
import { Menu, Dropdown as MenuFunc } from 'antd';
import Modal from 'react-modal';
import { computeCustomStyles, customStyles } from '../../../constants/modalStyles';
import Single from './Single';
import Bulk from './Bulk';
import SettlementFMenu from '../../../components/MenuOption/SettlementFMenu/SettlementFMenu';
import dayjs, { Dayjs } from 'dayjs';
import FilterModal from '../../../components/filterConfig/FilterModal';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../utils/datefunction';
import settlements from '../../../api/settlements';
import SettlementTransactions from './SettlementTransactions/SettlementTransactions';

const Settlements = () => {
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [modalSettlementTransaction, setModalSettlementTransaction] = React.useState({
		isOpened: false,
		data: null
	}); const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);
	const allSettlementApi = useApi(settlements.getAllSettlements);
	const settlement = allSettlementApi?.data?.data || {}
	// const [apiObject, setApiObject] = useState(null);

	// const { keyB: trxRef } = apiObject?.keyA || [{

	// }];

	const { currency } = useSelector((state) => state.appSettingsReducer);


	function closeModalSingle() {
		setIsOpenSingle(false);
	}

	function closeTransactionModalSingle() {
		setModalSettlementTransaction({
			isOpened: false,
			data: null
		});
		fetchSettlement();
	}
	function closeModalBulk() {
		setIsOpenBulk(false);
	}

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [paymentreference, setPaymentReference] = useState('');
	const [orderreference, setOrderReference] = useState('');
	const [status, setStatus] = useState('');
	const [email, setEmail] = useState('');
	const [bearer, setBearer] = useState(false);

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentReference('');
		setOrderReference('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray = [
		{
			name: 'Payment Reference',
			value: paymentreference,
			setValue: setPaymentReference,
		},
		{
			name: 'Order Reference',
			value: orderreference,
			setValue: setOrderReference,
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [
				{ name: 'Successful' },
				{ name: 'Initiated' },
				{ name: 'Failed' },
				{ name: 'Reversed' },
			],
		},
	];

	const fetchSettlement = async (isExport = false) => {
		status.replace(/-|\s/g, '');
		dispatch(openLoader());
		try {
			var data: any = await allSettlementApi.request(`currency=${currency}&email=${email}&settlementstatus=${status}&fromdate=${fromDate}&todate=${toDate}&size=${rowsPerPage}&page=${pageNumber}&export=${isExport}`)
			setBearer(false);
			console.log({ data })
			return data.items;
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchSettlement();
	}, [bearer, pageNumber, rowsPerPage, email, currency]);

	useEffect(() => {
		setPageNumber(settlement?.page?.currentPage || 1);
		setPreviousPage(settlement?.page?.previousPage);
		setNextPage(settlement?.page?.nextPage);
		setTableRow(dataSettlement());
		// setSettlement(_settlement);
	}, [allSettlementApi.data]);

	const openTransactionDetails = async (settlement: any) => {
		setModalSettlementTransaction({ isOpened: true, data: settlement })
	};

	const dataSettlement = () => {
		const tempArr: SettlementsFullData[] = [];
		const _settlement = settlement?.items || [];
		_settlement?.slice(0).reverse()
			.forEach((settlement: any, index: number) => {
				tempArr.push({
					businessName: settlement.subsidiaryName,
					destination: settlement.settlementDestination,
					merchantId: settlement.subsidiaryId,
					currency: settlement.currency,
					dateCreated: settlement.settlementDate,
					transactionCount: settlement.transactionCount,
					amount: `${settlement.currency} ${numberWithCommas(settlement?.settlementAmount)}`,
					date: moment(settlement?.settlementDate).format('MMMM Do YYYY, h:mm a'),
					_status: settlement?.settlementStatus,
					status: (
						<StatusView
							status={settlement?.settlementStatus}
							green='Completed'
							red='Failed'
							orange='Awaiting Approval | Iniated'
						/>
					),
					// action: <SettlementFMenu customerId={settlement.subsidiary_id} />,
				});
			});
		return tempArr;
	};

	const menu = (
		<Menu
			items={[
				{
					key: '1',
					label: (
						<p onClick={() => setIsOpenSingle(true)}>Log a single settlement</p>
					),
				},
				{
					key: '2',
					label: (
						<p onClick={() => setIsOpenBulk(true)}>Log bulk settlements</p>
					),
				},
			]}
		/>
	);

	return (
		<div className={styles.dashbordContainer}>
			<TableHeader
				pageName='All Settlement'
				data={settlement?.items}
				dataLength={settlement?.page?.total}
				value={email}
				setValue={setEmail}
				dropdown={dropdown}
				setDropdown={setDropdown}
				fetchDataHandler={() => fetchSettlement(true)}
				searchfn={true}
				placeHolder='Search by name'
				newButton={
					<MenuFunc overlay={menu}>
						<div className={styles.createLink}>Log settlement</div>
					</MenuFunc>
				}
				FilterComponent={
					<>
						<FilterModal
							eventDate={eventDate}
							setEventDate={setEventDate}
							dropdown={dropdown}
							setDropdown={setDropdown}
							setFromDate={setFromDate}
							setToDate={setToDate}
							fromDate={fromDate}
							toDate={toDate}
							setBearer={setBearer}
							clearHandler={clearHandler}
							filteredArray={filteredArray}
						/>
					</>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnSettlementsFull ? ColumnSettlementsFull : []}
				value={value}
				emptyPlaceHolder={
					settlement?.page?.total == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={settlement?.page?.total}
				totalPage={settlement?.page?.totalPage}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
				clickAction={(obj) => openTransactionDetails(obj)}

			/>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<Single closeModal={closeModalSingle} fn={fetchSettlement} />
			</Modal>
			{/* //MODAL FOR BULK */}
			<Modal
				isOpen={modalIsOpenBulk}
				onRequestClose={closeModalBulk}
				contentLabel='Example Modal'
				style={customStyles}>
				<Bulk closeModal={closeModalBulk} fn={fetchSettlement} />
			</Modal>
			<Modal
				isOpen={modalSettlementTransaction.isOpened}
				onRequestClose={closeTransactionModalSingle}
				contentLabel='Example Modal'
				style={computeCustomStyles("5%")}>
				<SettlementTransactions closeModal={closeTransactionModalSingle} data={modalSettlementTransaction.data} />
			</Modal>

		</div>
	);
};

export default Settlements;
