import * as React from 'react';
import Styles from './textfield.module.scss';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';

interface TextInputProps {
	inputLabel: string;
	inputType?: string;
	inputShrink?: boolean;
	notRequired?: boolean;
	InputHelper?: any;
	inputSelect?: boolean;
	inputOption?: any[];
	multiline?: boolean;
	inputName: string;
	rows?: number;
	style?: React.CSSProperties;
	inputValue?: string | number;
	InputRef?: any;
	onFocus?: any;
	handlePress?: any;
	multiple?: any;
	inputProps?: any;
	error?: any;
	autoComplete?: string;
	disabled?: boolean;
	handleChange?: React.ChangeEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	>;
}

const OutlineTextInput = ({
	inputLabel,
	inputType,
	notRequired,
	InputHelper,
	inputShrink,
	inputSelect = false,
	inputOption,
	multiline = false,
	inputName,
	inputValue,
	style,
	rows,
	onFocus,
	handleChange,
	handlePress,
	inputProps,
	InputRef,
	error,
	disabled,
	multiple,
	autoComplete = 'on',
}: TextInputProps) => {
	const dispatch = useDispatch();
	interface State {
		amount: string;
		password: string;
		weight: string;
		weightRange: string;
		showPassword: boolean;
	}

	const useStyles = makeStyles({
		root: { color: '#24BBA1 !important' },
		cssFocused: {
			fontFamily: 'HelveticaNeue',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '24px',
			display: 'flex',
			alignItems: 'center',
			letterSpacing: '-0.011em',
		},
		cssLabel: {
			color: '#24BBA1 !important',
		},
		cssOutlinedInput: {
			borderRadius: '10px !important',
			'&$cssFocused $notchedOutline': {
				borderColor: `#616161 !important`,
				borderWidth: '0.8px !important',
				borderRadius: '10px',
			},
		},
		notchedOutline: {
			borderColor: '#616161 !important',
			borderRadius: '6px',
		},
		helperText: {
			fontFamily: 'HelveticaNeue !important',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '12px',
			lineHeight: '15px',
			color: '#757575 !important',
		},
		input: {
			'&:invalid': {
				border: 'red solid 2px',
			},
		},
	});

	const classes = useStyles();

	return (
		<TextField
			id='outlined-basic'
			label={inputLabel}
			onChange={handleChange}
			onKeyPress={handlePress}
			placeholder={!inputValue ? inputLabel : ''}
			select={inputSelect}
			focused={!inputValue ? true : false}
			style={style}
			required={notRequired ? false : true}
			classes={{ root: classes.root }}
			className={Styles.inputField}
			name={inputName}
			value={inputValue}
			variant='outlined'
			size='medium'
			multiline={multiline}
			type={inputType ? inputType : ''}
			helperText={InputHelper ? InputHelper : ''}
			FormHelperTextProps={{ classes: { root: classes.helperText } }}
			rows={rows}
			error={error}
			disabled={disabled}
			onFocus={onFocus}
			autoComplete={autoComplete}
			// inputProps={inputProps}
			inputRef={InputRef}
			inputProps={{
				multiple: multiple,
				maxLength: multiline ? 250 : 50,
				minLength: multiline ? 10 : 1,
			}}
			InputLabelProps={{
				classes: {
					root: classes.cssLabel,
					focused: classes.cssFocused,
				},
				shrink: inputShrink,
			}}
			InputProps={{
				classes: {
					root: classes.cssOutlinedInput,
					focused: classes.cssFocused,
					notchedOutline: classes.notchedOutline,
				},
			}}>
			{inputOption?.map((option, index) => (
				<MenuItem
					value={option.value ? option.value : option.name}
					key={index}
					className={Styles.options}>
					{option.name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default OutlineTextInput;
