import client from "./client";

const getSettlements = () => client.get("settlement/all");
const getApprovedSettlements = (query) => client.get(`settlement/processed/all?${query}`);
const getSettlementTransactions = (query) => client.get(`settlement/transactions/summary?${query}`);
const getAllSettlements = (query) => client.get(`settlement/orders/settlements?${query}`);
const toggleFlagSettlement = (id, flag, payload) => client.patch(`settlement/${flag}/${id}`, payload);
const processSettlement = (payload) => client.post(`settlement/process/submit`, payload);
const processSettlementApproved = (settlementId) => client.post(`settlement/${settlementId}/process/approve`, {});

export default { getSettlements, getAllSettlements, getApprovedSettlements, getSettlementTransactions, toggleFlagSettlement, processSettlement, processSettlementApproved };
