import React, { useEffect } from 'react';
import styles from './Chargeback.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/Icons/closenew.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/Icons/u_copy.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/Icons/checkmarkgreen.svg';
import OutlineTextInput from '../../components/input/OutlineTextInput';
import { Divider, Select } from 'antd';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import client from '../../api/client';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import useApi from '../../hooks/useApi';
import currencyApi from '../../api/currencies';
import { closeLoader, openLoader } from '../../redux/actions/loader/loaderActions';
import moment from 'moment';

const { Option } = Select;
function Single({ closeModal, setBearer, orderDetails }: any) {
	const paymentTypes: any[] = [];

	const getCurrenciesApi = useApi(currencyApi.getCurrencies);

	const fetchCurrencies = async () => {
		getCurrenciesApi.request();
	};

	useEffect(() => {
		fetchCurrencies();
	}, []);

	const currencies = getCurrenciesApi?.data?.data;

	interface initTypes {
		currency: string;
		reference: string;
		amount: number;
		frequency: number;
		reason: string;
		dueDate: string;
		type: string;
	}

	console.log({ orderDetails })
	const initProps: initTypes = {
		currency: orderDetails?.currency,
		reference: orderDetails?.paymentReference,
		amount: orderDetails?.amount,
		frequency: 1,
		reason: '',
		dueDate: moment(new Date()).add(3, "days").toString(),
		type: 'Local',
	};

	const type = [
		{
			name: 'Local',
			label: 'Local',
		},
		{
			name: 'International',
			label: 'International',
		},
	];

	const stage = [
		{
			name: 'New',
			label: 'New',
		},
		{
			name: 'Won',
			label: 'Won',
		},
		{
			name: 'Lost',
			value: 'Lost',
		},
		{
			name: 'Pending',
			value: 'Pending',
		},
	];

	const disableFunction = () => {
		if (
			// !inputs.currency ||
			!inputs.reference ||
			!inputs.amount ||
			!inputs.frequency ||
			!inputs.reason ||
			!inputs.dueDate ||
			!inputs.type
		) {
			return true;
		}
	};
	const [inputs, setInputs] = React.useState(initProps);
	const dispatch = useDispatch();

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};
	// const onStateChange = (value) => {
	// 	console.log(value);

	// 	console.log(value);
	// };

	const submitHandler = async () => {
		try {
			dispatch(openLoader())
			const data: any = await client.post('/chargeback/upload', [inputs]);
			const message = data?.data?.message;
			closeModal();
			setBearer(true);
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
		finally {
			dispatch(closeLoader())
		}
	};

	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Log a single chargeback</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className={styles.modalcontent}>
				{/* <div className={styles.input}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='currency'
						inputLabel='Currency'
						inputSelect={true}
						inputOption={currencies}
						inputValue={inputs.currency}
						style={{ width: '100%' }}
					/>
				</div> */}
				<div style={{ display: 'flex' }}>
					<div className={styles.input}>
						<OutlineTextInput
							handleChange={updateProps}
							inputName='type'
							inputLabel='Type'
							inputOption={type}
							inputSelect={true}
							inputValue={inputs.type}
							style={{ width: '100%' }}
						/>
					</div>
					<div style={{ marginLeft: '16px' }} className={styles.input}>
						<OutlineTextInput
							disabled={!!orderDetails}
							handleChange={orderDetails ? () => { } : updateProps}
							inputName='reference'
							inputLabel='Reference'
							inputValue={inputs.reference}
							style={{ width: '100%' }}
						/>
					</div>
				</div>

				<div style={{ display: 'flex' }}>
					<div className={styles.input}>
						<OutlineTextInput
							handleChange={updateProps}
							inputName='amount'
							inputLabel='Amount'
							inputValue={inputs.amount}
							style={{ width: '100%' }}
						/>
					</div>

					<div style={{ marginLeft: '16px' }} className={styles.input}>
						<OutlineTextInput
							handleChange={updateProps}
							inputName='frequency'
							inputLabel='Frequency'
							inputValue={inputs.frequency}
							style={{ width: '100%' }}
						/>
					</div>
				</div>
				<div className={styles.input}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='dueDate'
						inputLabel='Due Date'
						inputValue={inputs.dueDate}
						inputType='date'
						style={{ width: '100%' }}
					/>
				</div>

				<div className={styles.input}>
					<OutlineTextInput
						handleChange={updateProps}
						inputName='reason'
						inputLabel='Reason for refund'
						multiline={true}
						inputValue={inputs.reason}
						style={{ width: '100%' }}
					/>
				</div>

				<div className={styles.checking}>
					<span style={{ marginRight: '10px' }}>
						<CheckIcon />
					</span>
					<p>Include weekends when determining due date</p>
				</div>

				<div className={styles.modalFooter}>
					<button className={styles.cancel} onClick={closeModal}>
						Cancel
					</button>

					<button
						onClick={submitHandler}
						disabled={disableFunction()}
						className={styles.fund}>
						Continue
					</button>
				</div>
			</div>
		</div>
	);
}

export default Single;
