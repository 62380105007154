import React, { useState, useEffect } from 'react';
import styles from './Channeldetails.module.scss';
import client from '../../../api/client';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import ChargebackIcon from '../../../assets/images/Icons/approve-white.svg';
import TimesIcon from '../../../assets/images/Icons/close.svg';

import StatusView from '../../../components/StatusView/StatusView';

interface detailProps {
	id: number;
	coreMerchant: any | undefined;
	merchantid: string;
	channel: string;
	paymentmethod: string;
	rail: string;
	scheme: string;
	bank: string;
	currency: string;
	mid: string;
	username: string;
	password: string;
	url: string;
	createdat: string;
	status: string;
	isActive?: boolean;
	useStatementDescriptor: string;
}

const ChannelDetails = ({
	customerDetails,
	setId,
}: {
	customerDetails: detailProps;
	setId: any;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	// const handleChargebacks = (e: React.SyntheticEvent) => {
	// 	e.preventDefault();
	// 	client
	// 		.post('/refund/transaction', payload)
	// 		.then((data: any) => {
	// 			const message = data?.data?.message;
	// 			dispatch(
	// 				openToastAndSetContent({
	// 					toastStyles: ToastSuccessStyles,
	// 					toastContent: message,
	// 				})
	// 			);
	// 		})
	// 		.catch((error: any) => {
	// 			if (error.response) {
	// 				const message = error?.response?.data?.message;
	// 				dispatch(
	// 					openToastAndSetContent({
	// 						toastStyles: ToastErrorStyles,
	// 						toastContent: message,
	// 					})
	// 				);
	// 			} else if (error.request) {
	// 				console.log(error.request);
	// 			} else {
	// 				console.log('Error', error.message);
	// 			}
	// 		});
	// };

	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<>
					<div className={styles.transactionInfo}>
						<div
							onClick={() => setId(null)}
							className={styles.transactionsHeader}>
							{'<'} Go Back
						</div>
					</div>
					<div className={styles.topTable}>
						<div className={styles.detailsHeader}>Channel details</div>
						<div className={styles.tableContent}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>ID</div>
								<div className={styles.detailsKey}>{customerDetails?.id}</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Merchant ID</div>
								<div className={styles.detailsKey}>
									{customerDetails?.coreMerchant?.merchantId}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Channel</div>
								<div className={styles.detailsKey}>
									{customerDetails?.channel}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Payment method</div>
								<div className={styles.detailsKey}>
									{customerDetails?.paymentmethod}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Currency</div>
								<div className={styles.detailsKey}>
									{customerDetails?.currency}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Card type</div>
								<div className={styles.detailsKey}>
									{customerDetails?.scheme}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Channel bank</div>
								<div className={styles.detailsKey}>
									{customerDetails?.bank || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Channel rate</div>
								<div className={styles.detailsKey}>
									{customerDetails?.rail || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Channel MID</div>
								<div className={styles.detailsKey}>{customerDetails?.mid}</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Statement Description</div>
								<div className={styles.detailsKey}>
									<StatusView
										status={customerDetails?.useStatementDescriptor ? "Merchant Name" : "Mid Name"}
										green='Merchant Name'
										orange='Mid Name'
									/>
								</div>
							</div>{' '}
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Status</div>
								<div className={styles.detailsKey}>
									<StatusView
										status={customerDetails?.isActive ?? false ? "ACTIVE" : "INACTIVE"}
										green='ACTIVE'
										orange='INACTIVE'
									/>
								</div>
							</div>{' '}
							{/* <div style={{ marginTop: '30px' }} className={styles.chargeBack}>
								<button
									style={{ marginBottom: '10px' }}
									className={styles.chargeBackbutton}>
									<div>
										<img src={ChargebackIcon} alt='chargeback' />
									</div>
									<div className={styles.ml}>Activate</div>
								</button>
								<button
									style={{ marginBottom: '10px' }}
									className={styles.chargeBackbutton}>
									<div>
										<img src={TimesIcon} alt='chargeback' />
									</div>
									<div className={styles.ml}>Deactivate</div>
								</button>
							</div> */}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ChannelDetails;
