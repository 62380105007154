/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react';
import styles from './Chargeback.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import {
	ColumnChargeback,
	ChargebackData,
} from '../../types/TablesTypes/Chargeback';
import StatusView from '../../components/StatusView/StatusView';
import TableHeader from '../../components/TableHeader/TableHeader';
import client from '../../api/client';
import { numberWithCommas } from '../../utils/formatNumber';
import moment from 'moment';
import {
	openLoader,
	closeLoader,
} from '../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastErrorStyles,
} from '../../constants/toastStyles';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import { Menu, Dropdown as MenuFunc } from 'antd';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import Single from './Single';
import Bulk from './ModalContents/Bulk';
import MenuOption from '../../components/MenuOption/MenuOption';
import { Dayjs } from 'dayjs';
import FilterModal from '../../components/filterConfig/FilterModal';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../utils/datefunction';
import * as Yup from 'yup';
import ResolveChargeback from './ModalContents/ResolveChargeback';
import FeedbackChargeBack from './ModalContents/FeedbackChargeBack';
import ExtendDueDate from './ModalContents/ExtendDueDate';
import { useHistory } from 'react-router-dom';

const ChargeBack = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);
	const [chargeback, setChargeback] = useState<any>();
	const [modalIsOpenAdd, setIsOpenAdd] = React.useState(false);
	const [modalIsOpenFeedback, setIsOpenFeedback] = React.useState(false);
	const [modalIsOpenExtend, setIsOpenExtend] = React.useState(false);
	const [object, setObject] = useState<any>({});
	const [activeState, setActiveState] = useState('Won');

	function closeModalSingle() {
		setIsOpenSingle(false);
		fetchChargeback();
	}
	function closeModalBulk() {
		setIsOpenBulk(false);
		fetchChargeback();
	}
	function closeModalAdd() {
		setIsOpenAdd(false);
		fetchChargeback();
	}
	function closeModalFeedback() {
		setIsOpenFeedback(false);
		fetchChargeback();
	}
	function closeModalExtend() {
		setIsOpenExtend(false);
		fetchChargeback();
	}
	const validate = Yup.object({
		comment: Yup.string().required('Required'),
		won: Yup.boolean().required('Required'),
	});
	const validate2 = Yup.object({
		comment: Yup.string().required('Required'),
	});
	const validate3 = Yup.object({
		new_due_date: Yup.string().required('Required'),
	});

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [paymentreference, setPaymentReference] = useState('');
	const [status, setStatus] = useState('');
	const [email, setEmail] = useState('');
	const [bearer, setBearer] = useState(false);

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentReference('');
		setStatus('');
		setEmail('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray = [
		{
			name: 'Payment Reference',
			value: paymentreference,
			setValue: setPaymentReference,
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [
				{ name: 'New' },
				{ name: 'Won' },
				{ name: 'Lost' },
				{ name: 'Pending' },
			],
		},
	];
	const identifierChange = (type: string) => {
		setActiveState(type);
	};
	const fetchChargeback = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`/chargeback/all?status=${status}&paymentreference=${paymentreference}&customeremail=${email}&fromdate=${fromDate}&todate=${toDate}&size=${rowsPerPage}&page=${pageNumber}`
			);
			setChargeback(data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchChargeback();
	}, [bearer, email, pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(chargeback?.data?.page?.currentPage || 1);
		setPreviousPage(chargeback?.data?.page?.previousPage);
		setNextPage(chargeback?.data?.page?.nextPage);
	}, [chargeback?.data]);
	const menuOptions = [
		{
			id: 4,
			name: 'View Details',
			onclick: (
				id: number
			) => {
				history.push(`/dashboard/chargeback/${id}/details`)
			},
		},
		{
			id: 1,
			name: 'Resolve chargeback',
			onclick: (
				id: number,
				amount: number,
				reference: string,
				dueDate: string,
				currency: string
			) => {
				setObject({ id, amount, reference, dueDate, currency });
				setIsOpenAdd(true);
			},
		},
		{
			id: 2,
			name: 'Feedback',
			onclick: (id: number, amount: number, reference: string) => {
				setObject({ id, amount, reference });
				setIsOpenFeedback(true);
			},
		},
		{
			id: 3,
			name: 'Extend chargeback due date',
			onclick: (id: number, amount: number, reference: string) => {
				setObject({ id, amount, reference });
				setIsOpenExtend(true);
			},
		},
	];

	const dataTransaction = () => {
		const tempArr: ChargebackData[] = [];
		chargeback?.data?.items
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					businessName: transaction.merchantName,
					transactionReference: transaction?.transactionReference,
					amount: `${transaction.currency} ${numberWithCommas(transaction?.amount)}`,
					date: moment(transaction?.dateCreated).format('MMMM Do YYYY, h:mm a'),
					dueDate: moment(transaction?.dueDate).format('MMMM Do YYYY, h:mm a'),
					status: (
						<StatusView
							status={transaction?.status}
							green='Won'
							red='Lost'
							orange='Pending'
							blue='New'
						/>
					),
					action: (
						<MenuOption
							menuContent={
								<div>
									{menuOptions.map((option: any) => (
										<div
											key={option?.id}
											onClick={(e) => {
												option?.onclick(
													transaction?.id,
													transaction?.amount,
													transaction?.transactionReference,
													transaction?.dueDate,
													transaction?.currency,
												);
											}}
											className={styles.menuContent}>
											<div className={styles.optionName}>{option?.name}</div>
										</div>
									))}
								</div>
							}
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [chargeback?.data?.items]);

	const itemLength = chargeback?.data?.items?.length;

	const menu = (
		<Menu
			items={[
				{
					key: '1',
					label: (
						<p onClick={() => setIsOpenSingle(true)}>Log a single chargeback</p>
					),
				},
				{
					key: '2',
					label: (
						<p onClick={() => setIsOpenBulk(true)}>Log bulk chargebacks</p>
					),
				},
			]}
		/>
	);

	return (
		<div className={styles.dashbordContainer}>
			<TableHeader
				pageName='All Chargebacks'
				data={chargeback?.data?.items}
				dataLength={chargeback?.data?.page?.total}
				value={email}
				setValue={setEmail}
				dropdown={dropdown}
				placeHolder='search by email address'
				setDropdown={setDropdown}
				newButton={
					<MenuFunc overlay={menu}>
						<div className={styles.createLink}>Log Chargeback</div>
					</MenuFunc>
				}
				searchfn={true}
				FilterComponent={
					<FilterModal
						eventDate={eventDate}
						setEventDate={setEventDate}
						dropdown={dropdown}
						setDropdown={setDropdown}
						setFromDate={setFromDate}
						setToDate={setToDate}
						fromDate={fromDate}
						toDate={toDate}
						setBearer={setBearer}
						clearHandler={clearHandler}
						filteredArray={filteredArray}
					/>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnChargeback ? ColumnChargeback : []}
				value={value}
				emptyPlaceHolder={
					chargeback?.data?.items?.length == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={chargeback?.data?.page?.total}
				totalPage={chargeback?.data?.page?.totalPage}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<Single setBearer={setBearer} closeModal={closeModalSingle} />
			</Modal>
			{/* //MODAL FOR BULK */}
			<Modal
				isOpen={modalIsOpenBulk}
				onRequestClose={closeModalBulk}
				contentLabel='Example Modal'
				style={customStyles}>
				<Bulk setBearer={setBearer} closeModal={closeModalBulk} />
			</Modal>
			{/* //MODAL FOR RESOLVED */}
			<Modal
				isOpen={modalIsOpenAdd}
				onRequestClose={closeModalAdd}
				contentLabel='Example Modal'
				style={customStyles}>
				<ResolveChargeback closeModal={closeModalAdd} activeState={activeState} object={object} identifierChange={identifierChange} />
			</Modal>

			{/* //MODAL FOR FEEDBACK */}
			<Modal
				isOpen={modalIsOpenFeedback}
				onRequestClose={closeModalFeedback}
				contentLabel='Example Modal'
				style={customStyles}>
				<FeedbackChargeBack closeModal={closeModalFeedback} object={object} validate2={validate2} />
			</Modal>
			{/* //MODAL FOR EXTEND */}
			<Modal
				isOpen={modalIsOpenExtend}
				onRequestClose={closeModalExtend}
				contentLabel='Example Modal'
				style={customStyles}>
				<ExtendDueDate closeModal={closeModalExtend} object={object} />
			</Modal>
		</div>
	);
};

export default ChargeBack;
