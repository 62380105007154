import React, { Fragment, useState } from "react";
import styles from "./Exportbutton.module.scss";
import { ReactComponent as DropArrow } from "../../assets/images/Icons/drop-arrow.svg";
import { exportToXLS } from "../../utils/exportToExcel";
import { CSVLink, CSVDownload } from "react-csv";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useDispatch } from "react-redux";
import { closeLoader, openLoader } from "../../redux/actions/loader/loaderActions";

const ExportButton = ({ data, fileName, headers, fetchDataHandler }: any) => {
  var dispatch = useDispatch();
  const [exportData, setExportData] = useState(data);
  const handleClick = () => {
    exportToXLS(exportData ?? data, fileName);
  };

  const [dropdown, setDropdown] = useState(false);

  const handleDropdown = async () => {
    if (!dropdown && (exportData?.length < 1 || exportData?.length != data?.length)) await fetchExportData();
    setDropdown(!dropdown);
  };

  console.log({ fetchDataHandler })
  const fetchExportData = async () => {
    if (!fetchDataHandler) return;
    dispatch(openLoader())
    const d = ((await fetchDataHandler()) || data || []);
    setExportData([...d])
    dispatch(closeLoader())
  };

  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.exportMenu}>
          <div className={styles.export} onClick={handleDropdown}>
            Export <DropArrow className={styles.dropArrow} />
          </div>

          {dropdown && (
            <div className={styles.exportDropdown}>
              <div onClick={handleClick} style={{ cursor: "pointer" }}>
                Export as .xls
              </div>
              <CSVLink
                data={exportData ? exportData : data}
                //   headers={headers}
                filename={fileName}
                className={styles.csv}
              >
                Export to .csv
              </CSVLink>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </Fragment>
  );
};

export default ExportButton;
