import React, { useState, useEffect } from "react";
import styles from "./AddChannel.module.scss";
import arrow from "../../../assets/IconsMerchant/Stroke 2.svg";
import { useHistory } from "react-router-dom";
import { Divider, Form, Input, InputNumber, Select, Switch } from "antd";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/closenew.svg";
import ChargebackIcon from "../../../assets/images/Icons/approve-white.svg";
import TimesIcon from "../../../assets/images/Icons/closenew.svg";
import Curency from "../../../api/currencies";
const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

function AddChannel({ closeModal, identifier, data, setIden }: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [INITIALVALUES, setInputs] = useState({
    channel: identifier === "edit" ? data?.channel : "",
    merchantId: identifier === "edit" ? data?.coreMerchant?.merchantId : "",
    paymentMethod: identifier === "edit" ? data?.paymentmethod : "",
    scheme: identifier === "edit" ? data?.scheme : "",
    channelBank: identifier === "edit" ? data?.bank : "",
    channelMid: identifier === "edit" ? data?.mid : "",
    channelRate: identifier === "edit" ? "nil" : "nil",
    rail: identifier === "edit" ? data?.rail : "",
    username: identifier === "edit" ? data?.username : "",
    password: identifier === "edit" ? data?.password : "",
    mccCode: identifier === "edit" ? data?.mccCode : "",
    remark: identifier === "edit" ? "nil" : "nil",
    url: identifier === "edit" ? data?.url : "",
    currency: identifier === "edit" ? data?.currency : "",
    isActive: identifier === "edit" ? data?.isActive ?? false : false,
    useStatementDescriptor: identifier === "edit" ? data?.useStatementDescriptor : false,
  })
  const [form] = Form.useForm();

  console.log({ data })

  console.log({ INITIALVALUES })
  const handleInputChange = (name: string, event: any) => {
    console.log({ event })
    setInputs({ ...INITIALVALUES, [name]: event })
  };
  const cancelHandler = () => {
    closeModal();
    setIden("");
  };
  const updatedHandler = (values: any) => {
    dispatch(openLoader());
    const path = identifier === "edit" ? "update" : "add"
    var method = identifier === "edit" ? client.patch : client.post;
    method(`payment/rails/${path}`, {
      ...values,
      // status: INITIALVALUES.status,
      isActive: INITIALVALUES.isActive,
      useStatementDescriptor: INITIALVALUES.useStatementDescriptor,
      id: data?.id,
    })
      .then((res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        cancelHandler();
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  const scheme = [
    { name: "VISA", id: 1 },
    { name: "MASTERCARD", id: 2 },
    { name: "VERVE", id: 3 },
  ];

  const paymeth = [
    {
      name: "Bank Transfer",
      value: "Bank_Transfer",
    },
    {
      name: "Card Payment",
      value: "Card",
    },
    {
      name: "Bank Account",
      value: "Bank_Account",
    },
    {
      name: "USSD",
      value: "USSD",
    },
    // {
    //   name: "Mobile Money",
    //   value: "Mobile Money",
    // },
    // {
    //   name: "Pay With Bank Transfer",
    //   value: "Pay With Bank Transfer",
    // },
    {
      name: "QR Code Payment",
      value: "NQR",
    },
  ];

  const [channels, setChannels] = useState([
    {
      name: "POS",
      shortName: "POS",
    },
    {
      name: "WEB",
      shortName: "WEB",
    },
  ]);

  const [rails, setRails] = useState([
    {
      name: "MPGS",
      shortName: "MPGS",
    },
    {
      name: "VISA",
      shortName: "VISA",
    },
    {
      name: "VERVE",
      shortName: "VERVE",
    },
  ]);

  const [allowedcurrencies, setAllowedCurrencies] = useState([
    {
      name: "Nigeria Naira",
      shortName: "NGN",
    },
  ]);

  useEffect(() => {
    Curency.getCurrencies()
      .then((res: any) => setAllowedCurrencies(res?.data?.data))
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className={styles.modalwrapper}>
      <div className={styles.modalhead}>
        <h3 className={styles.modalheadh3}>
          {identifier === "add" ? "Add new channel" : "Edit Channel"}
        </h3>
        <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
      </div>
      <Divider style={{ margin: 0, padding: 0 }} />
      <div className={styles.modalcontent}>
        <Form
          form={form}
          initialValues={INITIALVALUES}
          layout="vertical"
          name="nest-messages"
          onFinish={updatedHandler}
          validateMessages={validateMessages}
        >
          <div className={styles.flexInput}>
            <Form.Item
              className="selectOptions"
              style={{ flex: 1, marginRight: "16px" }}
              label="Channel"
              name="channel"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              {/* <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="channel"
              /> */}
              <Select defaultValue="WEB" placeholder="channel" allowClear>
                {channels?.length &&
                  channels?.map(({ name }, i) => (
                    <Option key={name} value={name}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Merchant ID"
              name="merchantId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="merchantId"
              />
            </Form.Item>
            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Payment Method"
              name="paymentMethod"
              className="selectOptions"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                defaultValue="Bank Transfer"
                placeholder="payment method"
                allowClear
              >
                {paymeth?.length &&
                  paymeth?.map(({ name, value }, i) => (
                    <Option key={i} value={value}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Currency"
              name="currency"
              // initialValue={"NGN"}
              className="selectOptions"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <select
                value={INITIALVALUES.currency}
                style={{
                  width: "100%",
                  padding: "20px 10px",
                  borderColor: "#d9d9d9",
                  borderRadius: "10px",
                }}
                name="currency"
                id="currency"
              >
                {allowedcurrencies?.length &&
                  allowedcurrencies?.map(({ name, shortName }) => (
                    <option key={shortName} value={shortName}>
                      {`${shortName} (${name})`}
                    </option>
                  ))}
              </select>
              {/* <Select>
                {allowedcurrencies?.length &&
                  allowedcurrencies?.map(({ name, short_name }) => (
                    <Option key={short_name} value={short_name}>
                      {`${name}(${short_name})`}
                    </Option>
                  ))}
              </Select> */}
            </Form.Item>
            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              name="scheme"
              label="Scheme"
              className="selectOptions"
              rules={[{ required: true }]}
            >
              <Select defaultValue="Visa" placeholder="scheme" allowClear>
                {scheme?.length &&
                  scheme?.map(({ name, id }, i) => (
                    <Option key={i} value={name}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Bank"
              name="channelBank"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="bank"
              />
            </Form.Item>

            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Channel Mid"
              name="channelMid"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="channelMid"
              />
            </Form.Item>
            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Mcc Code"
              name="mccCode"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="mccCode"
              />
            </Form.Item>
            <Form.Item
              className="selectOptions"
              style={{ flex: 1, marginRight: "16px" }}
              label="Rail"
              name="rail"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select defaultValue="MPGS" placeholder="rail" allowClear>
                {rails?.length &&
                  rails?.map(({ name }) => (
                    <Option key={name} value={name}>
                      {name}
                    </Option>
                  ))}
              </Select>
              {/* <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="rail"
              /> */}
            </Form.Item>

            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="username"
              />
            </Form.Item>
            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="password"
              />
            </Form.Item>
            <Form.Item
              style={{ flex: 1, marginRight: "16px" }}
              label="Remark"
              name="remark"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="remark"
              />
            </Form.Item>

            <Form.Item
              style={{ flex: 1 }}
              name="url"
              label="Url"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ borderRadius: "7px" }}
                className={styles.input}
                placeholder="url"
              />
            </Form.Item>
            <Form.Item name="status" label="" valuePropName="checked">
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>Use Merchant Name as Statement description</p> <Switch checked={INITIALVALUES.useStatementDescriptor} onChange={(value) => handleInputChange("useStatementDescriptor", value)} />
              </div>
            </Form.Item>
            <Form.Item name="status" label="" valuePropName="checked">
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "10px" }}>Status</p> <Switch checked={INITIALVALUES.isActive} onChange={(value) => handleInputChange("isActive", value ? true : false)} />
              </div>
            </Form.Item>
          </div>

          <Divider />

          <div className={styles.buttonflex}>
            <Form.Item shouldUpdate>
              {() => (
                <button type="submit" className={styles.submitHandler}>
                  <div>
                    <img src={ChargebackIcon} alt="chargeback" />
                  </div>
                  <div className={styles.ml}>
                    {identifier === "edit" ? "Update Channel" : "Add Channel"}
                  </div>
                </button>
              )}
            </Form.Item>

            <button onClick={cancelHandler} className={styles.cancel}>
              <div>
                <img src={TimesIcon} alt="chargeback" />
              </div>{" "}
              <div className={styles.ml}>Cancel</div>
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddChannel;
