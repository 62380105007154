import React, { useState, Fragment, useEffect } from 'react';
import styles from './Report.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import { ReportData, ColumnReport } from '../../../types/TablesTypes/Reports';
import StatusView from '../../../components/StatusView/StatusView';
import TableHeader from '../../../components/TableHeader/TableHeader';
import TransactionsMenu from '../../../components/MenuOption/TransactionsMenu/TransactionsMenu';
import transactionsApi from '../../../api/transactions';
import useApi from '../../../hooks/useApi';
import client from '../../../api/client';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	AppstoreOutlined,
	MailOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import Filtered from '../../../components/filterComponent/Filtered';
import { menuContent } from '../../../utils/menuContent';
import Mocked from './Mocked.json';
import RefundsMenu from '../../../components/MenuOption/RefundsMenu/RefundsMenu';
import { Menu, Dropdown as MenuFunc } from 'antd';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import Single from '../Settlements/Single';
import Bulk from '../Settlements/Bulk';
import dayjs, { Dayjs } from 'dayjs';
import FilterModal from '../../../components/filterConfig/FilterModal';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../utils/datefunction';

const Report = () => {
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);
	const [settlement, setSettlement] = useState<any>();
	// const [apiObject, setApiObject] = useState(null);

	// const { keyB: trxRef } = apiObject?.keyA || [{

	// }];

	function closeModalSingle() {
		setIsOpenSingle(false);
	}
	function closeModalBulk() {
		setIsOpenBulk(false);
	}

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [paymentreference, setPaymentReference] = useState('');
	const [orderreference, setOrderReference] = useState('');
	const [status, setStatus] = useState('');
	const [email, setEmail] = useState('');
	const [bearer, setBearer] = useState(false);

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentReference('');
		setOrderReference('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray = [
		{
			name: 'Payment Reference',
			value: paymentreference,
			setValue: setPaymentReference,
		},
		{
			name: 'Order Reference',
			value: orderreference,
			setValue: setOrderReference,
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [
				{ name: 'Successful' },
				{ name: 'Initiated' },
				{ name: 'Failed' },
				{ name: 'Reversed' },
			],
		},
	];

	const fetchSettlement = async () => {
		status.replace(/-|\s/g, '');
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`settlement/all?subsidiaryname=${email}&settlementstatus=${status}&paymentreference=${paymentreference}&transactionreference=${orderreference}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
			);
			setSettlement(data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchSettlement();
	}, [bearer, pageNumber, rowsPerPage, email]);

	useEffect(() => {
		setPageNumber(settlement?.currentPage || 1);
		setPreviousPage(settlement?.previousPage);
		setNextPage(settlement?.nextPage);
	}, [settlement]);




	const dataSettlement = () => {
		const tempArr: ReportData[] = [];
		settlement?.items
			?.slice(0)
			.reverse()
			.forEach((settlement: any, index: number) => {
				tempArr.push({
					transactionReference: settlement.transactionReference,
					destination: settlement.destination,
					amount: settlement.transactionAmount,
					initiate: settlement.initiate,
					date: settlement.dateCreated,
					status: (
						<StatusView
							status={settlement?.settlementStatus}
							green='Completed'
							red='Failed'
							orange='Pending'
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataSettlement());
	}, [settlement?.items]);

	return (
		<div className={styles.dashbordContainer}>
			<TableHeader
				pageName='Report'
				data={settlement?.items}
				dataLength={settlement?.totalItems}
				value={email}
				setValue={setEmail}
				dropdown={dropdown}
				setDropdown={setDropdown}
				searchfn={true}
				placeHolder='Search by name'
				FilterComponent={
					<>
						<FilterModal
							eventDate={eventDate}
							setEventDate={setEventDate}
							dropdown={dropdown}
							setDropdown={setDropdown}
							setFromDate={setFromDate}
							setToDate={setToDate}
							fromDate={fromDate}
							toDate={toDate}
							setBearer={setBearer}
							clearHandler={clearHandler}
							filteredArray={filteredArray}
						/>
					</>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnReport ? ColumnReport : []}
				value={value}
				emptyPlaceHolder={
					settlement?.totalItems == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={settlement?.totalItems}
				totalPage={settlement?.totalPages}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>
		</div>
	);
};

export default Report;
