import React from 'react';

export interface RefundData {
	date: string;
	amount: string;
	businessName: string;
	customerName?: string;
	merchantId: string;
	amountCharged: string;
	transactionReference: string;
	status: any;
	// action: React.ReactNode
}

export const ColumnRefund = [
	{
		Header: 'Due date',
		accessor: 'date',
	},
	{
		Header: 'Merchant ID',
		accessor: 'merchantId',
	},
	{
		Header: 'Business name',
		accessor: 'businessName',
	},
	{
		Header: 'Customer name',
		accessor: 'customerName',
	},
	{
		Header: 'Amount refunded',
		accessor: 'amount',
	},
	{
		Header: 'Amount charged',
		accessor: 'amountCharged',
	},
	{
		Header: 'Order reference',
		accessor: 'transactionReference',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	// {
	// 	Header: '',
	// 	accessor: 'action',
	// },
];
