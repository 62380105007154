import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Business.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import {
	ColumnSubsidiaries,
	SubsidiariesData,
} from '../../types/TablesTypes/Subsidiaries';
import MenuOption from '../../components/MenuOption/MenuOption';
import StatusView from '../../components/StatusView/StatusView';
import TableHeader from '../../components/TableHeader/TableHeader';
import ViewIcon from '../../assets/images/Icons/view-details.svg';
import DeactivateIcon from '../../assets/images/Icons/deactivate-user.svg';
import { ReactComponent as LinkIcon } from '../../assets/images/Icons/link-icon.svg';
import subsidiariesApi from '../../api/subsidiaries';
import useApi from '../../hooks/useApi';
import client from '../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../constants/toastStyles';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import CreateCustomer from '../../components/ModalContent/CreateCustomer/CreateCustomer';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import SubsidiariesDetails from './SubsidiariesDetails';
import moment from 'moment';
import FilterModal from '../../components/filterConfig/FilterModal';
import dayjs, { Dayjs } from 'dayjs';
import currencyApi from '../../api/currencies';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../utils/datefunction';

const tableBusinessType = [{ name: 'Individual' }, { name: 'Company' }];

const Subsidiaries = () => {
	const history = useHistory();
	const [tableRow, setTableRow] = useState<any[]>();
	const getSubsidiariesApi = useApi(subsidiariesApi.getSubsidiaries);
	const [details, setDetails] = useState<number | null>(null);
	const [businessName, setBusinessName] = useState<string>('');
	const [subsidiaries, setSubsidiaries] = useState<any>();
	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	const dispatch = useDispatch();

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [email, setEmail] = useState('');
	const [bearer, setBearer] = useState(false);
	const [businessname, setBusinessname] = useState('');
	const [name, setName] = useState('');
	const [businessType, setBusinessType] = useState<string>('');

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setEmail('');
		setBusinessname('');
		setName('');
		setBusinessType('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray = [
		{
			name: 'Business Type',
			value: businessType,
			setValue: setBusinessType,
			selective: tableBusinessType,
		},
		{
			name: 'Business Name',
			value: businessname,
			setValue: setBusinessname,
		},
		{
			name: 'Email Address',
			value: email,
			setValue: setEmail,
		},
	];

	const fetchSubsidiaries = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`subsidiary/all/paginated?name=${name}&email=${email}&businesstype=${businessType}&fromdate=${fromDate}&todate=${toDate}&businessname=${businessname}&limit=${rowsPerPage}&page=${pageNumber}`
			);
			setSubsidiaries(data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchSubsidiaries();
	}, [bearer, name, pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(subsidiaries?.currentPage || 1);
		setPreviousPage(subsidiaries?.previousPage);
		setNextPage(subsidiaries?.nextPage);
	}, [subsidiaries]);

	const menuOptions = [
		{
			id: 1,
			name: (id: number) => {
				return 'View Details';
			},
			icon: ViewIcon,
			onclick: (
				emailAddress: string,
				e: any,
				status: string,
				subsidiaryId: number,
				businessName: string
			) => {
				setDetails(subsidiaryId);
				setBusinessName(businessName);
			},
		},
	];

	const dataSubsidiaries = () => {
		const tempArr: SubsidiariesData[] = [];
		subsidiaries?.items
			?.slice(0)
			.reverse()
			.forEach((subsidiary: any, index: number) => {
				return tempArr.push({
					businessName: subsidiary?.name,
					email: subsidiary?.email,
					businessType: subsidiary?.businessType,
					country: subsidiary?.country,
					users: subsidiary?.userCount,
					subsidiaryGroup: subsidiary?.subsidiaryGroup,
					action: (
						<MenuOption
							menuContent={
								<div>
									{menuOptions.map((option: any) => (
										<div
											key={option?.id}
											onClick={(e) => {
												option?.onclick(
													subsidiary?.emailAddress,
													e,
													subsidiary?.status,
													subsidiary?.id,
													subsidiary?.businessName
												);
											}}
											className={styles.menuContent}>
											<img
												src={option?.icon}
												alt={option?.name(subsidiary?.id)}
												width={24}
												height={24}
											/>
											<div className={styles.optionName}>
												{option?.name(subsidiary?.status)}
											</div>
										</div>
									))}
								</div>
							}
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataSubsidiaries());
	}, [subsidiaries?.items]);

	const itemLength = subsidiaries?.items?.length;

	return (
		<div style={{ minHeight: '100vh' }}>
			{!details ? (
				<>
					<TableHeader
						pageName='Subsidiaries'
						data={subsidiaries?.items}
						dataLength={subsidiaries?.totalItems}
						value={name}
						setValue={setName}
						dropdown={dropdown}
						setDropdown={setDropdown}
						placeHolder='Search by business name'
						FilterComponent={
							<FilterModal
								eventDate={eventDate}
								setEventDate={setEventDate}
								dropdown={dropdown}
								setDropdown={setDropdown}
								setFromDate={setFromDate}
								setToDate={setToDate}
								fromDate={fromDate}
								toDate={toDate}
								setBearer={setBearer}
								clearHandler={clearHandler}
								filteredArray={filteredArray}
							/>
						}
					/>

					{getSubsidiariesApi.loading ? (
						<div className={styles.loading} />
					) : (
						<PaginationTable
							data={tableRow ? tableRow : []}
							columns={ColumnSubsidiaries ? ColumnSubsidiaries : []}
							value={value}
							emptyPlaceHolder={
								subsidiaries?.totalItems == 0
									? 'You currently do not have any data'
									: 'Loading...'
							}
							total={subsidiaries?.totalItems}
							totalPage={subsidiaries?.totalPages}
							pageNumber={pageNumber}
							setPageNumber={setPageNumber}
							nextPage={nextPage}
							setNextPage={setNextPage}
							previousPage={previousPage}
							setPreviousPage={setPreviousPage}
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
						/>
					)}
				</>
			) : (
				<SubsidiariesDetails
					id={details}
					setId={setDetails}
					name={businessName}
				/>
			)}
		</div>
	);
};

export default Subsidiaries;
