import React, { useState, useEffect } from 'react';
import Styles from './Dashboard.module.scss';
import { styled } from '@mui/material/styles';
import Grid from '@material-ui/core/Grid';
import Card from '../../../components/Card/Card';
import Chart from '../../../components/Chart/Chart';
import ChartOutflow from '../../../components/Chart/ChartOutflow';
import BalanceCard from '../../../components/Card/BalanceCard';
import TimerIcon from '../../../assets/images/Icons/timer.svg';
import VolumeIcon from '../../../assets/images/Icons/volume-icon.svg';
import SettlementIcon from '../../../assets/images/Icons/settlement-icon.svg';
import BalanceIcon from '../../../assets/images/Icons/balance-card-icon.svg';
import LedgerIcon from '../../../assets/images/Icons/ledger-icon.svg';
import useApi from '../../../hooks/useApi';
import transactionsSummaryApi from '../../../api/transactionsSummary';
import topMerchantApi from '../../../api/topMerchant';
import inflowOutflowApi from '../../../api/inflow';
import client from '../../../api/client';
import ChartDashboard from '../../../components/ChartDashboard/ChartDashboard';
import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import LinearProgress, {
	linearProgressClasses,
} from '@mui/material/LinearProgress';
import { PieChart } from 'react-minimal-pie-chart';
import { useSelector } from 'react-redux';
const randomColor = require('randomcolor');

export interface ITopMerchant {
	logo: string;
	name: string;
	value: number;
	totalCount: number;
}

interface TopCountType {
	name: string;
	emailAddress: string;
	count: number;
}

interface TopSpendingType {
	name: string;
	logo: string;
	value: number;
	totalCount: number;
}
interface valueType {
	name: string;
	value: number;
}

const Dashboard = () => {
	const [filterParam, setFilterParam] = useState<any>('year');
	const [inflowData, setInflowData] = useState<any>();

	const [selected, setSelected] = useState<number | undefined>(0);
	const getTransactionsSummaryApi = useApi(
		transactionsSummaryApi.getTransactionsSummary
	);
	const appconfigs = useSelector((state) => state.appSettingsReducer);

	useEffect(() => {
		getTransactionsSummaryApi.request(appconfigs?.currency);
	}, []);

	const getTopMerchantApi = useApi(topMerchantApi.getTopMerchant);
	const getInflowOutflowApi = useApi(inflowOutflowApi.getInflowOutflow);
	const getPiechartTransactionMethod = useApi(
		inflowOutflowApi.getPiechartTransactionMethod
	);
	const getPiechartTransactionStatus = useApi(
		inflowOutflowApi.getPiechartTransactionStatus
	);
	const getPiechartBank = useApi(inflowOutflowApi.getPiechartBank);
	const getFailureReason = useApi(inflowOutflowApi.getFailureReason);
	const getPayDay = useApi(inflowOutflowApi.getPayDay);
	const getCustomerSpending = useApi(inflowOutflowApi.getCustomerSpending);
	const getCustomerCount = useApi(inflowOutflowApi.getCustomerCount);
	const getCustomerDevices = useApi(inflowOutflowApi.getCustomerDevices);

	const fetchInflow = async () => {
		const { data }: any = await client.get(
			`metric/inflow/outflow?type=${filterParam}`
		);
		setInflowData(data?.data);
	};

	useEffect(() => {
		fetchInflow();
	}, [filterParam]);

	useEffect(() => {
		getTopMerchantApi.request();
		getInflowOutflowApi.request();
		getPiechartTransactionMethod.request();
		getTransactionsSummaryApi.request(appconfigs?.currency);
		getPiechartTransactionStatus.request();
		getPiechartBank.request();
		getFailureReason.request();
		getPayDay.request();
		getCustomerSpending.request();
		getCustomerCount.request();
		getCustomerDevices.request();
	}, [appconfigs?.currency]);

	const data = getTransactionsSummaryApi?.data?.data;
	const pTransactionMethod = getPiechartTransactionMethod?.data?.data;
	const pTransactionStatus = getPiechartTransactionStatus?.data?.data;
	const usedBank = getPiechartBank?.data?.data;
	const failureReason = getFailureReason?.data?.data;
	const topSpending = getCustomerSpending?.data?.data;
	const payDay = getPayDay?.data?.data;
	const topCount = getCustomerCount?.data?.data;
	const devicesUsed = getCustomerDevices?.data?.data;

	const colorBank = randomColor({
		count: `${usedBank?.length}`,
	});
	const colorDevice = randomColor({
		count: `${devicesUsed?.length}`,
	});

	const colorStatus = ['#EB5757', '#6FCF97'];
	const newListBank = usedBank?.map((item: any) => {
		item['title'] = item?.name;
		item['color'] = colorBank[0];

		colorBank.shift();
		return item;
	});

	const newListDevices = devicesUsed?.map((newItem: any) => {
		if (!newItem.name) newItem.name = "Unknown"
		const rawname = newItem?.name?.split('/');
		if (rawname?.length > 0) newItem['title'] = rawname[0]
		else newItem['title'] = "Unknown"
		newItem['color'] = colorDevice[0];
		colorDevice.shift();
		return newItem;
	});

	const newListBalance = pTransactionStatus?.map((item: any) => {
		item['title'] = item?.name;
		item['color'] = colorStatus[0];

		colorStatus.shift();
		return item;
	});

	const response = {
		count: data?.count,
		volume: data?.volume,
		settlements: data?.settlements,
		settlementChange: data?.lastWeekSettlementsPercentChange ?? 0,
		volumeChange: data?.lastWeekVolumePercentChange ?? 0,
		countChange: data?.lastWeekCountPercentChange ?? 0,
	};

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: '24px',
		borderRadius: 4,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor:
				theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 4,
			backgroundColor: theme.palette.mode === 'light' ? '#80C09B' : '#80C09B',
		},
	}));
	return (
		<div className={Styles.dashbordContainer}>
			{getTransactionsSummaryApi.loading ? (
				<div className={Styles.loading} />
			) : (
				<>
					<Grid container spacing={2}>
						<Grid item md={4} xs={12}>
							<Card
								icon={BalanceIcon}
								percentage={response.volumeChange}
								amount={response.volume}
								transactionType='Total transaction balance'
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<Card
								icon={TimerIcon}
								percentage={response.countChange}
								amount={response.count}
								transactionType='Total transaction count'
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<Card
								icon={SettlementIcon}
								percentage={response.settlementChange}
								amount={response.settlements}
								transactionType='Total transaction settlements'
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<div className={Styles.chart}>
								<ChartDashboard
									inflowData={inflowData}
									filterParam={filterParam}
									setFilterParam={setFilterParam}
								/>
							</div>
						</Grid>
					</Grid>

					{/* <div className={styles.balance}>
						<Grid container spacing={2}>
							<Grid item md={6} xs={12}>
								<div className={styles.dashboardChart}>hhshshshshshshshsh</div>
							</Grid>
							<Grid item md={6} xs={12}>
								<div className={styles.dashboardChart}>
									hshshshshshshshshshs
								</div>
							</Grid>
						</Grid>
					</div> */}
					<div className={Styles.tableWrapper}>
						<OverviewTable
							title='What devices do my merchants use the most?'
							subTitle='Types of devices used by your merchants'>
							<div className={Styles.deviceContainer}>
								<div>
									<PieChart
										style={{ height: '130px', width: '200px' }}
										animate={true}
										data={newListDevices?.slice(0, 5)}
										segmentsStyle={{
											transition: 'stroke .3s',
											cursor: 'pointer',
										}}
										segmentsShift={(index) => (index === selected ? 2 : 0)}
										onMouseOver={(_, index) => {
											setSelected(index === selected ? undefined : index);
										}}
										onMouseOut={(_, index) => {
											setSelected(index === selected ? undefined : index);
										}}
										lineWidth={60}
										paddingAngle={4}
									/>

									{/* <h4 className={Styles.headerh4}>1,175 customers</h4> */}
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										flexWrap: 'wrap',
										marginTop: '30px',
									}}>
									{newListDevices
										?.slice(0, 5)
										?.map(
											(
												{ name, color }: { name: 'string'; color: 'string' },
												i: number
											) => (
												<div>
													<div>
														<div
															style={{ backgroundColor: `${color}` }}
															className={Styles.status}></div>
														<h4
															style={{
																fontSize: '16px',
																margin: 0,
																padding: 0,
															}}
															className={Styles.headerh4}>
															{name.split('/')[0]}
														</h4>
													</div>
												</div>
											)
										)}
								</div>
							</div>
						</OverviewTable>
						<OverviewTable
							title='When do my merchants get paid the most?'
							subTitle='The days my merchants receive payment'>
							<div className={Styles.weekDaysContainer}>
								{payDay?.map(({ name, value }: valueType, i: number) => (
									<div>
										<BorderLinearProgress variant='determinate' value={value} />
										<p>{name}</p>
									</div>
								))}
							</div>
						</OverviewTable>
						<OverviewTable
							title='What payment option do my merchants use the most?'
							subTitle='Check out the most used payment options'>
							<div className={Styles.paymentContainer}>
								{pTransactionMethod?.map(
									({ name, value }: valueType, i: number) => (
										<div>
											<p>{name}</p>
											<div className={Styles.progressBar}>
												<BorderLinearProgress
													variant='determinate'
													value={value}
												/>
											</div>
										</div>
									)
								)}
							</div>
						</OverviewTable>
						<OverviewTable
							title='Top merchants by spending'
							subTitle='Check out your top merchants that spend the most'>
							<div className={Styles.listWrapper}>
								{topSpending
									?.slice(0, 3)
									.map(
										(
											{ name, value }: TopSpendingType,
											i: number
										) => (
											<div className={Styles.listItem}>
												<div>
													<h4
														style={{ fontSize: '16px' }}
														className={Styles.headerh4}>
														{name}
													</h4>
													<span className={Styles.headerSpan}></span>
												</div>
												<div>
													<h4
														style={{ fontSize: '16px' }}
														className={Styles.headerh4}>
														{value}
													</h4>
													<span className={Styles.headerSpan}>
														Amount spent
													</span>
												</div>
											</div>
										)
									)}
							</div>
						</OverviewTable>
						<OverviewTable
							title='What banks do my merchants use the most?'
							subTitle='Types of banks used by your merchants'>
							<div className={Styles.deviceContainer}>
								<div>
									<PieChart
										style={{ height: '130px', width: '200px' }}
										animate={true}
										data={newListBank}
										segmentsStyle={{
											transition: 'stroke .3s',
											cursor: 'pointer',
										}}
										segmentsShift={(index) => (index === selected ? 2 : 0)}
										onMouseOver={(_, index) => {
											setSelected(index === selected ? undefined : index);
										}}
										onMouseOut={(_, index) => {
											setSelected(index === selected ? undefined : index);
										}}
										lineWidth={60}
										paddingAngle={4}
									/>
									{/* <h4 className={Styles.headerh4}>1,175 customers</h4> */}
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										marginTop: '30px',
									}}>
									{newListBank?.map(
										(
											{ name, color }: { name: 'string'; color: 'string' },
											i: number
										) => (
											<div>
												<div>
													<div
														style={{ backgroundColor: `${color}` }}
														className={Styles.status}></div>
													<h4
														style={{ fontSize: '16px', margin: 0, padding: 0 }}
														className={Styles.headerh4}>
														{name}
													</h4>
												</div>
											</div>
										)
									)}
								</div>
							</div>
						</OverviewTable>
						<OverviewTable
							title='Top 3 merchants by number of transactions'
							subTitle='Check out your top merchants with high transaction volume'>
							<div className={Styles.listWrapper}>
								{topCount
									?.slice(0, 3)
									.map(
										(
											{ name, value }: TopSpendingType,
											i: number
										) => (
											<div className={Styles.listItem}>
												<div>
													<h4
														style={{ fontSize: '16px' }}
														className={Styles.headerh4}>
														{name}
													</h4>
													<span className={Styles.headerSpan}></span>
												</div>
												<div>
													<h4
														style={{ fontSize: '16px' }}
														className={Styles.headerh4}>
														{value}
													</h4>
													<span className={Styles.headerSpan}>
														No of transaction
													</span>
												</div>
											</div>
										)
									)}
							</div>
						</OverviewTable>
						<OverviewTable
							title='Success rate'
							subTitle='The percentage rate of successful and failed transactions'>
							<div className={Styles.deviceContainer}>
								<div>
									<PieChart
										style={{ height: '120px', width: '200px' }}
										animate={true}
										data={newListBalance}
										segmentsStyle={{
											transition: 'stroke .3s',
											cursor: 'pointer',
										}}
										segmentsShift={(index) => (index === selected ? 2 : 0)}
										onMouseOver={(_, index) => {
											setSelected(index === selected ? undefined : index);
										}}
										onMouseOut={(_, index) => {
											setSelected(index === selected ? undefined : index);
										}}
										lineWidth={60}
										paddingAngle={4}
									/>
									{/* <h4 className={Styles.headerh4}>1,175 customers</h4> */}
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										marginTop: '50px',
									}}>
									{newListBalance?.map(
										(
											{ name, color }: { name: 'string'; color: 'string' },
											i: number
										) => (
											<div style={{ marginRight: '16px', marginTop: '-35px' }}>
												<div>
													<div
														style={{ backgroundColor: `${color}` }}
														className={Styles.status}></div>
													<h4
														style={{ fontSize: '16px', margin: 0, padding: 0 }}
														className={Styles.headerh4}>
														{name}
													</h4>
												</div>
											</div>
										)
									)}
								</div>
							</div>
						</OverviewTable>
						<OverviewTable
							title='Reasons for transactions failure'
							subTitle='Check out top reasons why transactions fail'>
							<ol className={Styles.transactionContainer}>
								{failureReason?.map(
									(
										{ name, reason }: { name: 'string'; reason: 'string' },
										i: number
									) => (
										<li>{reason}</li>
									)
								)}
							</ol>
						</OverviewTable>
					</div>
				</>
			)}
		</div>
	);
};

export default Dashboard;
