import React, { useState, Fragment, useEffect } from 'react';
import styles from './Fraud.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import { ColumnFruad, FruadData } from '../../../types/TablesTypes/Fraud';
import StatusView from '../../../components/StatusView/StatusView';
import TableHeader from '../../../components/TableHeader/TableHeader';
import TransactionsMenu from '../../../components/MenuOption/TransactionsMenu/TransactionsMenu';
import transactionsApi from '../../../api/transactions';
import useApi from '../../../hooks/useApi';
import client from '../../../api/client';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	AppstoreOutlined,
	MailOutlined,
	SettingOutlined,
} from '@ant-design/icons';
import Filtered from '../../../components/filterComponent/Filtered';
import { menuContent } from '../../../utils/menuContent';
import Mocked from './fraud.json';
import RefundsMenu from '../../../components/MenuOption/RefundsMenu/RefundsMenu';
import { Menu, Dropdown as MenuFunc } from 'antd';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import { Dayjs } from 'dayjs';
import FilterModal from '../../../components/filterConfig/FilterModal';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../utils/datefunction';

const Fraud = () => {
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [fruad, setFruad] = useState<any>();
	// const [apiObject, setApiObject] = useState(null);

	// const { keyB: trxRef } = apiObject?.keyA || [{

	// }];

	function closeModalSingle() {
		setIsOpenSingle(false);
	}

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [paymentmethod, setPaymentMethod] = useState('');
	const [subsidiary, setSubsidiary] = useState('');
	const [status, setStatus] = useState('');
	const [merchantName, setMerchantName] = useState('');

	const [bearer, setBearer] = useState(false);

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentMethod('');
		setSubsidiary('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
		setMerchantName('');
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray = [
		{
			name: 'Payment Method',
			value: paymentmethod,
			setValue: setPaymentMethod,
		},
		{
			name: 'subsidiary Id',
			value: subsidiary,
			setValue: setSubsidiary,
		},
	];

	const fetchChargeback = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`fraud/risk/fraud/summary?merchantname=${merchantName}&fromdate=${fromDate}&todate=${toDate}&size=${rowsPerPage}&page=${pageNumber}`
			);
			setFruad(data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchChargeback();
	}, [bearer, pageNumber, rowsPerPage, merchantName]);

	useEffect(() => {
		setPageNumber(fruad?.current_page || 1);
		setPreviousPage(fruad?.previous_page);
		setNextPage(fruad?.next_page);
	}, [fruad]);


	const dataTransaction = () => {
		const tempArr: FruadData[] = [];
		fruad?.data?.items
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					merchantName: transaction.subsidiaryName,
					totalVolume: transaction.volume,
					totalValue: `NGN${transaction?.value}`,
					acceptanceType: transaction?.acceptanceType,
					cardType: transaction?.cardType,
					// action: <RefundsMenu customerId={transaction.customer_id} />,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [fruad?.data?.items]);

	const itemLength = fruad?.data?.items?.length;

	const menu = (
		<Menu
			items={[
				{
					key: '1',
					label: (
						<p onClick={() => setIsOpenSingle(true)}>Log a single refund</p>
					),
				},
			]}
		/>
	);
	// newButton={
	// 				<MenuFunc overlay={menu}>
	// 					<div className={styles.createLink}>Log Refund</div>
	// 				</MenuFunc>
	// 			}

	return (
		<div style={{ marginTop: '38px', minHeight: '100vh' }}>
			<TableHeader
				pageName='Fraud Chargeback Summary'
				data={fruad?.data?.items}
				dataLength={fruad?.data?.page?.total}
				value={merchantName}
				setValue={setMerchantName}
				dropdown={dropdown}
				setDropdown={setDropdown}
				placeHolder='Search by Merchant Name'
				exporting={false}
				filtering={false}
				FilterComponent={
					<FilterModal
						eventDate={eventDate}
						setEventDate={setEventDate}
						dropdown={dropdown}
						setDropdown={setDropdown}
						setFromDate={setFromDate}
						setToDate={setToDate}
						fromDate={fromDate}
						toDate={toDate}
						setBearer={setBearer}
						clearHandler={clearHandler}
						filteredArray={filteredArray}
					/>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnFruad ? ColumnFruad : []}
				value={value}
				emptyPlaceHolder={
					fruad?.data?.page?.total == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={fruad?.data?.page?.total}
				totalPage={fruad?.data?.page?.totalPage}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>

			{/* //MODAL FOR SINGLE */}
			{/* <Modal
				isOpen={modalIsOpenSingle}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<Single closeModal={closeModalSingle} />
			</Modal> */}
		</div>
	);
};

export default Fraud;
