import React from 'react';
import styles from './Card.module.scss';
import TimerIcon from '../../assets/images/Icons/timer.svg';
import { ReactComponent as ArrowIncrease } from '../../assets/images/Icons/trending-increase.svg';
import { ReactComponent as ArrowDecrease } from '../../assets/images/Icons/trending-decrease.svg';
import { numberWithCommas } from '../../utils/formatNumber';
import { useSelector } from 'react-redux';

type CardProps = {
	transactionType?: string;
	percentage?: number;
	amount?: number;
	icon?: string;
	currency?: string;
};
const Card = ({ transactionType, percentage, amount, icon, currency }: CardProps) => {
	const appconfigs = useSelector((state) => state.appSettingsReducer);
	if (!!!currency) currency = appconfigs.currency;
	return (
		<div className={styles.card}>
			<div className={styles.cardContent}>
				<div className={styles.cardCount}>{transactionType}</div>

				{transactionType === 'Transaction settlements' ||
					transactionType === 'Transaction volume' ||
					transactionType === 'Ledger Balance' ||
					transactionType === 'Available balance' ||
					transactionType === 'Total transaction balance' ||
					transactionType === 'Total transaction settlements' ||
					transactionType === 'Ledger balance' ||
					transactionType === 'Chargebacks' ||
					transactionType === 'Blocked funds' ? (
					<div className={styles.cardAmountNgn}>
						<span>{currency}</span>
						{amount && numberWithCommas(amount)}
						{/* <div className={styles.doubleZero}>00</div> */}
					</div>
				) : (
					<div className={styles.cardAmount}>{amount}</div>
				)}
				{
					(!!percentage && percentage > 0 ? (
						<div className={styles.cardPercent}>
							<div className={styles.percentIncrease}>
								{/* <ArrowIncrease /> */}
								<span>+ {percentage}%</span>
							</div>
							<div className={styles.timeLine}>from last week</div>
						</div>
					) : (
						<div className={styles.cardPercent}>
							<div className={styles.percentDecrease}>
								{/* <ArrowDecrease /> */}
								<span>- {percentage}%</span>
							</div>
							<div className={styles.timeLine}>from last week</div>
						</div>
					))}
			</div>
			<div>
				<img src={icon} alt='timer' height={48} width={48} />
			</div>
		</div>
	);
};

export default Card;
