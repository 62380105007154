import React, { useState, useEffect } from 'react';
import styles from './SettlementPlan.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import TableHeader from '../../../components/TableHeader/TableHeader';
import client from '../../../api/client';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch, useSelector } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Mark from '../../../assets/images/Icons/u_save.svg';
import { Divider } from 'antd';
import { Grid } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import { Dayjs } from 'dayjs';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../utils/datefunction';
import * as Yup from 'yup';
import FilterModal from '../../../components/filterConfig/FilterModal';
import SelectWrapper from '../../../components/Select';
import moment from 'moment';
import { numberWithCommas } from '../../../utils/formatNumber';
import FeesModal from '../../../components/ModalContent/FeesModal/FeesModal';
import MenuOption from '../../../components/MenuOption/MenuOption';
import { ColumnSettlementPlans, settlementPlanData } from '../../../types/TablesTypes/settlementPlan';
import SettlementcycleModal from '../../../components/ModalContent/SettlementcycleModal/SettlementcycleModal';

const SettlementPlan = ({ data }: any) => {
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState({
		isOpened: false, data: null
	});
	const [settlementPlans, setSettlementPlans] = useState<any>({ items: data ?? [] });

	function closeModalSingle() {
		setIsOpenSingle({ isOpened: false, data: null });
		fetchBusinesses();
	}

	function openModalSingle(data: any = null) {
		console.log({ data })
		setIsOpenSingle({ isOpened: true, data: data });
	}

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [country, setCountry] = useState('');
	const [name, setName] = useState('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);
	const [businesstype, setBusinesstype] = useState('');

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setCountry('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
		setBusinesstype('');
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray = [
		{
			name: 'Business Type',
			value: businesstype,
			setValue: setBusinesstype,
			selective: [{ name: 'Individual' }, { name: 'Company' }],
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [
				{ name: 'Active' },
				{ name: 'Pending-Verification' },
				{ name: 'New' },
				{ name: 'Pending-Approval' },
			],
		},
	];

	const fetchBusinesses = async () => {
		if (data) return;
		dispatch(openLoader());
		try {
			const res: any = await client.get(
				`settlement/plan/view?name=${name}&size=${rowsPerPage}&page=${pageNumber}`
			);
			setSettlementPlans(res.data.data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchBusinesses();
	}, [bearer, pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(settlementPlans?.page?.currentPage || 1);
		setPreviousPage(settlementPlans?.page?.previousPage);
		setNextPage(settlementPlans?.page?.nextPage);
	}, [settlementPlans]);

	const dataTransaction = () => {
		const tempArr: settlementPlanData[] = [];
		settlementPlans?.items?.forEach((transaction: any, index: number) => {
			tempArr.push({
				id: transaction.id,
				partialOrFull: transaction.partialOrFull,
				settlementType: transaction.partialOrFull == "P" ? "Partial" : "Full",
				fullPaymentDays: transaction.fullPaymentDays,
				finalPaymentDays: `${transaction.fullPaymentDays} day(s)`,
				partialPaymentDays: transaction?.partialPaymentDays,
				initialPaymentDays: `${transaction?.partialPaymentDays} day(s)`,
				percentage: transaction?.percentage * 100,
				initialPercentage: `${Number(transaction?.percentage * 100).toFixed(2)}%`,
				finalPercentage: `${(Number(1 - transaction?.percentage) * 100).toFixed(2)}%`,
				currency: transaction?.currency?.shortName || transaction?.currency,
				currencyId: transaction?.currency?.id || transaction?.currencyId,
				settlementGroup: transaction?.settlementGroup?.name || transaction?.settlementGroup,
				settlementGroupId: transaction?.settlementGroupId,
				name: transaction?.name,
				settlementDestination: transaction?.settlementDestination?.name,
				settlementDestinationId: transaction?.settlementDestination?.id,
				isActive: transaction?.isActive,
				dateCreated: moment(transaction?.dateCreated).format('MMMM Do YYYY, h:mm a'),
				action: (
					<MenuOption
						menuContent={
							<div>
								{[].map((option: any) => (
									<div
										key={option?.id}
										onClick={(e) => {
											option?.onclick(transaction, e);
										}}
										className={styles.menuContent}>
										<img
											src={option?.icon}
											alt={option?.name()}
											width={24}
											height={24}
										/>
										<div className={styles.optionName}>{option?.name()}</div>
									</div>
								))}
							</div>
						}
					/>
				),
			});
		});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [settlementPlans?.items]);

	return (
		<div style={{ marginTop: '38px' }}>
			<TableHeader
				pageName='Settlement Plans'
				dataLength={settlementPlans?.page?.total}
				data={settlementPlans?.items}
				value={value}
				setValue={setValue}
				exporting={false}
				filtering={false}
				searchfn={false}
				newButton={
					<div
						onClick={() => openModalSingle()}
						className={styles.createLink}>
						Add New Plan
					</div>
				}
				FilterComponent={
					<FilterModal
						eventDate={eventDate}
						setEventDate={setEventDate}
						dropdown={dropdown}
						setDropdown={setDropdown}
						setFromDate={setFromDate}
						setToDate={setToDate}
						fromDate={fromDate}
						toDate={toDate}
						setBearer={setBearer}
						clearHandler={clearHandler}
						filteredArray={filteredArray}
					/>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnSettlementPlans ? ColumnSettlementPlans : []}
				value={value}
				emptyPlaceHolder={
					settlementPlans?.page?.total == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={settlementPlans?.page?.total}
				totalPage={settlementPlans?.page?.totalPage}
				pageNumber={pageNumber}
				clickAction={(value: any) => { openModalSingle(value) }}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle.isOpened}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<SettlementcycleModal data={modalIsOpenSingle.data} closeModal={closeModalSingle} cb={fetchBusinesses} />
			</Modal>
		</div>
	);
};

export default SettlementPlan;
